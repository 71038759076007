import extend from 'lodash/extend';

import api from '@ihme/common/api';
import request from '@ihme/common/api-request';
import config from './config';

api.setApiUrl(config.apiUrl);

export default extend(api, {

    data: {
        getCauseOutcomeFilters: criteria =>
            request.get('data/cause-outcome-filters', criteria, { timeout: 100000 }),

        // { location_id, age_group_id, gender_id, measure_id, metric_id, year, cause_id }
        getCauseOutcome: (criteria, cancelToken = null) => {
            const params = { timeout: 30000 };
            if (cancelToken) {
                params.cancelToken = cancelToken;
            }
            return request.get('data/cause-outcome', criteria, params);
        },
    },

    getExtendedCauses: (search, page, per_page) =>
        request.get('extended-causes', {
            search,
            page,
            per_page,
        }),

});
