// complete ES6 polyfill for all older browsers & devices
import '@babel/polyfill';
import '@ihme/common/polyfills';
// hot loader helper
import 'react-hot-loader';

import React from 'react';
import ReactDOM from 'react-dom';
import { StyleRoot } from 'radium';

import {
    localStorageAdapter,
    StorageProvider,
} from '@ihme/common/packages/storage';
import ThemeProvider from '@ihme/common/theme/ThemeProvider';

import Root from './components/Root';
import config from './config';

import defaultTheme from './theme/default';
import { init as initLocales } from './locale';

initLocales();

ReactDOM.render(
    <ThemeProvider theme={defaultTheme}>
        <StorageProvider
            version={config.storageCacheVersion}
            storageAdapter={localStorageAdapter}>
            <StyleRoot>
                <Root/>
            </StyleRoot>
        </StorageProvider>
    </ThemeProvider>,
    document.getElementById('root')
);
