import React from 'react';
import PropTypes from 'prop-types';

import _ from '@ihme/common/locale';
import locale from '../locale';

import View from './View';

const propTypes = {
    locationId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,

    rowData: PropTypes.object,
};

export default class Chart extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            minValue: 0,
            maxValue: 0,
            errors: undefined,
            ...this.getStateFromProps(props),
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState(this.getStateFromProps(nextProps));
    }

    getStateFromProps = props =>
        props.rowData
            ? this.parseResponse(props.locationId, props.rowData)
            : null;

    parseResponse = (locationId, response) => {
        const { columns, records } = response;
        const valueIndex = columns.indexOf('value');
        const locationIndex = columns.indexOf('location_id');

        const data = records
            .map(record => {
                const result = {
                    name: _(`location_${record[locationIndex]}`),
                    value: Math.round(record[valueIndex] * 10) / 10,
                };

                if (record[locationIndex] === locationId) {
                    result.label = {
                        show: true,
                        fontWeight: 'bold',
                        formatter: `\n${result.name}\n{a|${result.value}}`,
                        padding: [4, 10],
                        borderRadius: 4,
                        borderColor: 'rgba(0,0,0,0.2)',
                        borderWidth: 0.5,
                        shadowColor: 'rgba(0,0,0,0.2)',
                        shadowBlur: 10,
                        shadowOffsetX: 10,
                        shadowOffsetY: 10,
                        backgroundColor: 'rgba(255,255,255)',
                        rich: {
                            a: {
                                fontWeight: 'normal',
                                lineHeight: 30,
                            },
                        },
                    };
                }

                return result;
            });

        const values = data.map(record => record.value);

        const maxValue = Math.max(...values);
        const minValue = Math.min(...values);

        return {
            data,
            maxValue,
            minValue,
        };
    };

    render() {
        const {
            data,
            minValue,
            maxValue,
        } = this.state;

        return (
            <View
                data={data}
                title={_(locale.chartTitle)}
                subtitle={_(locale.chartSubTitle)}
                minValue={minValue}
                maxValue={maxValue}
                isLoading={this.props.isLoading}
            />
        );
    }
}

Chart.propTypes = propTypes;
