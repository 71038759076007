import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import debounce from 'lodash/debounce';
import numeral from 'numeral';

import Echarts from 'react-echarts-v3';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';

import _ from '@ihme/common/locale';
import locale from './locale';

import echartsTheme from '../../../theme/echartsTheme';

import { Col, Clearfix } from '@ihme/common/web/components';

const propTypes = {
    locationId: PropTypes.number.isRequired,
    globalData: PropTypes.array.isRequired,
    locationData: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

class View extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: window.innerWidth,
        };

        this.onResize = debounce(this.onResize.bind(this), 250);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        this.setState({ windowWidth: window.innerWidth });
    }

    render() {
        const isCompactView = this.state.windowWidth < 993;
        let chartHeight = 500;
        const globalLocationStyle = styles.globalLocation;
        const selectedLocationStyle = styles.selectedLocation;

        let chartRadius = '80%';
        let innerRadius = 40;
        let verticalCenter = 20;

        if (isCompactView) {
            chartHeight = 800;

            if (this.state.windowWidth > 650) {
                verticalCenter = 25;
                chartHeight = 1000;
                chartRadius = '60%';
                innerRadius = 30;
            }
            else if (this.state.windowWidth > 520) {
                chartHeight = 1000;
                chartRadius = '65%';
                innerRadius = 30;
            }
            else if (this.state.windowWidth > 455) {
                chartHeight = 800;
                chartRadius = '70%';
                innerRadius = 35;
            }

            globalLocationStyle.top = verticalCenter + '%';
            globalLocationStyle.left = '50%';

            selectedLocationStyle.top = (verticalCenter + 45) + '%';
            selectedLocationStyle.left = '50%';
        }
        else {
            globalLocationStyle.top = '45%';
            globalLocationStyle.left = '25%';

            selectedLocationStyle.top = '45%';
            selectedLocationStyle.left = '75%';
        }

        return (
            <div>
                <Col xs={12}>
                    <h5 style={styles.title}>{_(locale.title)}</h5>
                    <p style={{ textAlign: 'center' }}>{_(locale.text, { location: _(`location_${this.props.locationId}`) })}</p>

                    <div style={{
                        ...styles.chartContainer,
                        height: chartHeight,
                    }}>
                        <Echarts
                            resizable
                            loading={this.props.isLoading}
                            optsLoading={echartsTheme.loading}
                            style={{
                                ...styles.chart,
                                height: chartHeight,
                            }}
                            option={{
                                tooltip: {
                                    ...echartsTheme.tooltip,
                                    trigger: 'item',
                                    formatter: params =>
                                        `${params.name}<br/><b>${numeral(params.value)
                                            .format('0,0.0')}</b>`,
                                },
                                legend: {
                                    orient: 'horizontal',
                                    x: 'center',
                                    y: 'bottom',
                                    width: '90%',
                                    textStyle: echartsTheme.textStyle,
                                    data: this.props.globalData.map(record => ({
                                        name: record.name,
                                        icon: 'circle',
                                    })),
                                },
                                calculable: true,
                                series: [
                                    {
                                        type: 'pie',
                                        name: _('location_1'),
                                        data: this.props.globalData,
                                        center: isCompactView ? ['50%', verticalCenter + '%'] : ['25%', '45%'],
                                        radius: isCompactView ? [chartRadius, innerRadius + '%'] : ['30%', '70%'],
                                        markPoint: {
                                            symbol: 'circle',
                                            symbolSize: 50,
                                        },
                                        label: {
                                            show: true,
                                            position: isCompactView ? 'inside' : 'outside',
                                            formatter: data =>
                                                isCompactView
                                                    ? data.percent < 5 ? '' : data.percent + ' %'
                                                    : data.percent + ' %',
                                            color: isCompactView ? 'black' : 'auto',
                                        },
                                    },
                                    {
                                        type: 'pie',
                                        name: _(`location_${this.props.locationId}`),
                                        data: this.props.locationData,
                                        center: isCompactView ? ['50%', (verticalCenter + 45) + '%'] : ['75%', '45%'],
                                        radius: isCompactView ? [chartRadius, innerRadius + '%'] : ['30%', '70%'],
                                        label: {
                                            show: true,
                                            position: isCompactView ? 'inside' : 'outside',
                                            formatter: data =>
                                                isCompactView
                                                    ? data.percent < 5 ? '' : data.percent + ' %'
                                                    : data.percent + ' %',
                                            color: isCompactView ? 'black' : 'auto',
                                        },
                                    },
                                    {
                                        ...styles.donutShadow,
                                        center: isCompactView ? ['50%', verticalCenter + '%'] : ['25%', '45%'],
                                        radius: isCompactView ? [innerRadius + '%', (innerRadius + 8) + '%'] : ['30%', '38%'],
                                    },
                                    {
                                        ...styles.donutShadow,
                                        center: isCompactView ? ['50%', (verticalCenter + 45) + '%'] : ['75%', '45%'],
                                        radius: isCompactView ? [innerRadius + '%', (innerRadius + 8) + '%'] : ['30%', '38%'],
                                    },
                                ],
                            }}
                        />
                        <span style={globalLocationStyle}>{_('location_1')}</span>
                        <span style={selectedLocationStyle}>{_(`location_${this.props.locationId}`)}</span>
                    </div>
                </Col>
                <Clearfix />
            </div>
        );
    }
}

const styles = {
    title: {
        textAlign: 'center',

    },
    chartContainer: {
        position: 'relative',
        textAlign: 'center',
    },
    globalLocation: {
        position: 'absolute',
        top: '45%',
        left: '25%',
        transform: 'translate(-50%, -50%)',
        width: 120,
        fontWeight: 'bold',
        fontSize: 18,
        color: '#333333',
    },
    selectedLocation: {
        position: 'absolute',
        top: '45%',
        left: '75%',
        transform: 'translate(-50%, -50%)',
        width: 120,
        fontWeight: 'bold',
        fontSize: 18,
        color: '#333333',
    },
    donutShadow: {
        type: 'pie',
        name: 'shadow',
        hoverAnimation: false,
        cursor: 'default',
        data: [
            {
                value: 1,
                itemStyle: { color: 'rgba(0, 0, 0, 0.1)' },
            },
        ],
        tooltip: {
            show: false,
        },
        label: {
            show: false,
        },
    },
};

View.propTypes = propTypes;

export default Radium(View);
