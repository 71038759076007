import * as types from '../actions/types';

const initialState = {
    extendedCauses: null,
    globalImpactFilters: null,
    breakdownFilters: null,
    trendsFilters: null,
    factsFilters: null,
    globalYllData: null,
    countries: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.EXTENDED_CAUSES_LOADED: {
            return {
                ...state,
                extendedCauses: action.payload,
            };
        }

        case types.TRENDS_FILTERS_LOADED: {
            return {
                ...state,
                trendsFilters: action.payload,
            };
        }

        case types.GLOBAL_IMPACT_FILTERS_LOADED: {
            return {
                ...state,
                globalImpactFilters: action.payload,
            };
        }

        case types.BREAKDOWN_FILTERS_LOADED: {
            return {
                ...state,
                breakdownFilters: action.payload,
            };
        }

        case types.FACTS_FILTERS_LOADED: {
            return {
                ...state,
                factsFilters: action.payload,
            };
        }

        case types.GLOBAL_YLL_DATA_LOADED: {
            return {
                ...state,
                globalYllData: action.payload,
            };
        }

        case types.COUNTRIES_LOADED: {
            return {
                ...state,
                countries: action.payload,
            };
        }

        default:
            return state;
    }
}

export const getTrendsFilters = state => state.trendsFilters;
export const getExtendedCauses = state => state.extendedCauses;
export const getGlobalImpactFilters = state => state.globalImpactFilters;
export const getBreakdownFilters = state => state.breakdownFilters;
export const getFactsFilters = state => state.factsFilters;
export const getGlobalYllData = state => state.globalYllData;
export const getCountries = state => state.countries;
