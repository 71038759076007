import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

import config from '../../config';

const propTypes = {
    location: ReactRouterPropTypes.location.isRequired,
};

class GATracker extends React.Component {
    componentDidMount() {
        ReactGA.initialize(config.googleAnalyticsId);
        this.trackPage(this.props.location.pathname);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.trackPage(this.props.location.pathname);
        }
    }

    trackPage(path) {
        ReactGA.pageview(path);
    }

    render() {
        return null;
    }
}

GATracker.propTypes = propTypes;

export default withRouter(GATracker);
