import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import _ from '@ihme/common/locale';
import {
    ABOUT_PATH,
    HOME_PATH,
    GLOBAL_IMPACT_PATH,
    TRENDS_PATH,
    BREAKDOWN_PATH,
    FACTSHEETS_PATH,
    HIV_PATH,
} from '../../router/paths';

import locale from './locale';

import View from './View';

const contextTypes = {
    router: PropTypes.object.isRequired,
};

class Container extends React.PureComponent {
    render() {
        return (
            <View
                {...this.props}
                items={[
                    {
                        key: 'home',
                        action: HOME_PATH,
                        title: _(locale.home),
                    },
                    {
                        key: 'impact',
                        action: GLOBAL_IMPACT_PATH,
                        title: _(locale.impact),
                    },
                    {
                        key: 'trends',
                        action: TRENDS_PATH,
                        title: _(locale.trends),
                    },
                    {
                        key: 'breakdown',
                        action: BREAKDOWN_PATH,
                        title: _(locale.breakdown),
                    },
                    {
                        key: 'factsheets',
                        action: FACTSHEETS_PATH,
                        title: _(locale.factsheets),
                    },
                    {
                        key: 'about',
                        action: ABOUT_PATH,
                        title: _(locale.about),
                    },
                    {
                        key: 'moreStories',
                        title: _(locale.moreStories),
                        subItems: [
                            {
                                key: 'hiv',
                                action: HIV_PATH,
                                title: _(locale.hiv),
                            },
                        ],
                    },
                ]}
            />
        );
    }
}

Container.contextTypes = contextTypes;

export default withRouter(
    connect(
        null,
        null,
        null,
        { pure: false }
    )(Container)
);
