import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadGlobalYllData } from '../../../actions/data-actions';
import { getGlobalYllData } from '../../../reducers';

import View from './View';

const propTypes = {
    locationId: PropTypes.number.isRequired,
    loadGlobalYllData: PropTypes.func.isRequired,
    data: PropTypes.object,
};

const mapStateToProps = state => ({
    data: getGlobalYllData(state),
});

class YLL extends React.PureComponent {
    componentWillMount() {
        if (this.props.data == null) {
            this.props.loadGlobalYllData();
        }
    }

    render() {
        const { data } = this.props;

        return (
            <View
                data={data}
                locationId={this.props.locationId}
                isLoading={data == null}
            />
        );
    }
}

YLL.propTypes = propTypes;

export default connect(mapStateToProps, { loadGlobalYllData })(YLL);
