import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import groupBy from 'lodash/groupBy';
import remove from 'lodash/remove';

import _ from '@ihme/common/locale';
import { calculateFontSize } from '@ihme/common/theme/echarts-theme';

import Echarts from 'react-echarts-v3';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';

import echartsTheme from '../../../../theme/echartsTheme';
import config from '../../../../config';
import locale from '../locale';

import { dashedLineIcon, normalLineIcon } from './icons';

const propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    locationId: PropTypes.number.isRequired,
};

export default class View extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: window.innerWidth,
            chartWidth: window.innerWidth,
        };
    }

    renderTooltip = params => {
        const title = `<b>${params[0].data.year}</b>`;

        const locations = Object.values(
            groupBy(params,
                record => record.data.locationId));

        const globalData = remove(locations,
            record => record[0].data.locationId === config.globalLocationId)[0];

        const locationData = locations[0];

        const globalTitle = _(`location_${config.globalLocationId}`);
        const locationTitle = _(`location_${locationData[0].data.locationId}`);

        const formatValue = value =>
            parseInt(value) < 1
                ? value
                : numeral(value)
                    .format('0,0');

        return `<div style='display:flex; flex-direction:row; font-family: "Helvetica", sans-serif; font-size: 14px'>` +

            `<div style='flex-direction: column;padding: 10px;'><div>${title}</div>` +
            globalData.map(cause => `<div>${cause.marker}&nbsp;&nbsp;${cause.seriesName}:</div>`)
                .join('') +
            `</div>` +

            `<div style='flex-direction: column;padding: 10px; text-align: right;'><div>${globalTitle}</div>` +
            globalData.map(cause => `<div><b>${formatValue(cause.data.value)}</b></div>`)
                .join('') +
            `</div>` +

            `<div style='flex-direction: column;padding: 10px; text-align: right;'><div>${locationTitle}</div>` +
            locationData.map(cause => `<div><b>${formatValue(cause.data.value)}</b></div>`)
                .join('') +
            `</div>`;
    };

    axisLabelFormatter = value =>
        numeral(value)
            .format(value > 999999 ? '0.0a' : '0a')
            .toUpperCase();

    getYAxisStyle = () => ({
        nameLocation: 'center',
        nameTextStyle: {
            ...echartsTheme.textStyle,
            fontWeight: 'bold',
            lineHeight: 90,
            rich: {},
        },
        ...echartsTheme.yAxis,
        axisTick: { show: false },
        axisLabel: {
            ...echartsTheme.textStyle,
            formatter: this.axisLabelFormatter,
        },
    });

    getFontSize = title =>
        calculateFontSize(
            title.split('\n')[2], // @todo: get rid of hardcode, calculate longest subline
            this.state.chartWidth - 10,
            document.getElementById('canvasFontSizer'),
            echartsTheme.title.textStyle.fontSize,
            echartsTheme.title.textStyle.fontFamily,
            echartsTheme.title.textStyle.fontWeight,
        );

    onResize = width => this.setState({ chartWidth: width });

    render() {
        const {
            data,
            title,
            isLoading,
        } = this.props;

        const { windowWidth } = this.state;

        const isMobileView = windowWidth < 768;

        const gridStyle = windowWidth > 460
            ? {
                left: 70,
                top: 120,
                right: 70,
            }
            : {
                left: 70,
                right: 70,
                top: 150,
            };

        return (
            <React.Fragment>
                <Echarts
                    resizable
                    loading={isLoading}
                    onResize={this.onResize}
                    optsLoading={echartsTheme.loading}
                    theme={echartsTheme}
                    style={{ ...styles.container }}
                    option={{
                        title: {
                            text: title,
                            textStyle: {
                                ...echartsTheme.title.textStyle,
                                fontSize: this.getFontSize(title),
                            },
                        },
                        tooltip: {
                            trigger: 'axis',
                            formatter: this.renderTooltip,
                            position: isMobileView ? [0, '50%'] : 'auto',
                        },
                        legend: [
                            {
                                color: 'black',
                                top: 70,
                                width: '100%',
                                data: data.map(item => ({
                                    name: item.name,
                                    icon: 'circle',
                                })),
                            },
                            {
                                color: 'black',
                                top: windowWidth > 460 ? 92 : 120,
                                width: '100%',
                                selectedMode: false,
                                data: [
                                    {
                                        name: _('location_1'),
                                        icon: normalLineIcon,
                                    },
                                    {
                                        name: _(`location_${this.props.locationId}`),
                                        icon: dashedLineIcon,
                                    },
                                ],
                            },
                        ],
                        grid: gridStyle,
                        xAxis: {
                            data: data && data.length
                                ? data[0].values.map(value => value.year)
                                : [],
                            ...echartsTheme.xAxis,
                        },
                        yAxis: [
                            {
                                name: _(locale.leftAxisLabel),
                                ...this.getYAxisStyle(),
                            },
                            {
                                name: _(locale.rightAxisLabel, { location: _(`location_${this.props.locationId}`) }),
                                ...this.getYAxisStyle(),
                            },
                        ],
                        series: data.map(lineData => ({
                            yAxisIndex: lineData.axisId,
                            name: lineData.name,
                            data: lineData.values,
                            itemStyle: {
                                color: lineData.color,
                            },
                            lineStyle: {
                                type: lineData.locationId === config.globalLocationId
                                    ? 'solid'
                                    : 'dashed',
                            },
                            ...styles.chart,
                        }))
                        // to display secondary legend we have to add data with the same names
                            .concat([
                                {
                                    name: _('location_1'),
                                    ...styles.chart,
                                },
                                {
                                    name: _(`location_${this.props.locationId}`),
                                    ...styles.chart,
                                },
                            ]),
                    }}
                />
                <canvas id="canvasFontSizer" width="0" height="0"/>
            </React.Fragment>
        );
    }
}

const styles = {
    container: {
        height: 480,
    },
    title: {
        top: 0,
        left: 'center',
        backgroundColor: 'rgba(255, 255, 255, .8)',
        textStyle: {
            fontSize: 18,
            fontFamily: 'Helvetica, sans-serif',
            fontWeight: 400,
            color: '#333',
        },
    },
    chart: {
        type: 'line',
        showSymbol: false,
        smooth: true,
    },

};

View.propTypes = propTypes;
