import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import config from '../config';
import _ from '@ihme/common/locale';

const moveOptionAtStart = (source, itemId) => {
    const selectedItem = remove(source, item => item.value === itemId);

    if (selectedItem && selectedItem.length) {
        source.unshift(selectedItem[0]);
    }
};

export const sortLocationOptions = locations => {
    const result = sortBy(
        locations.map(location => ({
            ...location,
            sortKey: _(`location_${location.value}`),
        })), 'sortKey');

    moveOptionAtStart(result, config.lowSdiLocationId);
    moveOptionAtStart(result, config.lowMiddleSdiLocationId);
    moveOptionAtStart(result, config.middleSdiLocationId);
    moveOptionAtStart(result, config.highMiddleSdiLocationId);
    moveOptionAtStart(result, config.highSdiLocationId);
    moveOptionAtStart(result, config.globalLocationId);

    return result;
};

// Implementation for old dropdown components that use just ids as source
const isExists = (source, item) => source.indexOf(item) !== -1;

// Implementation for old dropdown components that use just ids as source
const moveToEnd = (source, item) => {
    let result = source;
    if (isExists(source, item)) {
        result = source.slice(0);
        result.push(
            result.splice(
                result.indexOf(item),
                1)[0]);
    }

    return result;
};

// Implementation for old dropdown components that use just ids as source
const moveAtStart = (source, item) => {
    let result = source;
    if (isExists(source, item)) {
        result = source.slice(0);
        result.unshift(
            result.splice(
                result.indexOf(item),
                1)[0]);
    }

    return result;
};

// Implementation for old dropdown components that use just ids as source
export const sortAgeGroups = ageGroups => moveToEnd(ageGroups, config.allAgesGroupId);

// Implementation for old dropdown components that use just ids as source
export const sortLocations = locations => {
    let result = sortBy(
        locations.map(id => ({
            id,
            name: _(`location_${id}`),
        })), 'name')
        .map(location => location.id);

    result = moveAtStart(result, config.lowSdiLocationId);
    result = moveAtStart(result, config.lowMiddleSdiLocationId);
    result = moveAtStart(result, config.middleSdiLocationId);
    result = moveAtStart(result, config.highMiddleSdiLocationId);
    result = moveAtStart(result, config.highSdiLocationId);
    result = moveAtStart(result, config.globalLocationId);

    return result;
};
