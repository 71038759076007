import React from 'react';
import PropTypes from 'prop-types';

import api from '../../../../api';
import config from '../../../../config';

import View from './View';

const propTypes = {
    locationId: PropTypes.number.isRequired,
};

export default class Highlight extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            globalData: [],
            locationData: [],
        };

        this.mounted = false;

        this.setStateIfMounted = this.setStateIfMounted.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        this.loadData(config.globalLocationId);
        this.loadData(this.props.locationId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.locationId !== this.props.locationId) {
            this.loadData(nextProps.locationId);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setStateIfMounted(state, callback) {
        if (this.mounted) {
            this.setState(state, callback);
        }
    }

    loadData(locationId) {
        const isGlobal = locationId === config.globalLocationId;
        const criteria = {
            location_id: locationId,
            age_group_id: config.allAgesGroupId,
            gender_id: config.bothGendersId,
            measure_id: config.deathMeasureId,
            metric_id: config.numberMetricId,
            cause_id: config.hepatitisCauseId,
            year: `2000,${config.latestYear}`,
        };

        api.data.getCauseOutcome(criteria)
            .then(response => {
                const dataField = isGlobal ? 'globalData' : 'locationData';
                this.setStateIfMounted({
                    [dataField]: this.parseResponse(response),
                });
            })
            // @todo: add errors catching
            .catch(console.log);
    }

    parseResponse(response) {
        const { columns, records } = response;
        const yearIndex = columns.indexOf('year');
        const valueIndex = columns.indexOf('value');

        return records.map(record => ({
            year: record[yearIndex],
            value: record[valueIndex],
        }));
    }

    render() {
        const {
            globalData,
            locationData,
            isLoading,
        } = this.state;

        return (
            <View
                locationId={this.props.locationId}
                globalData={globalData}
                locationData={locationData}
                isLoading={isLoading}
            />
        );
    }
}

Highlight.propTypes = propTypes;
