export default {
    title: 'breakdown_scene_title',
    highlightText: 'breakdown_scene_highlight_text',
    saveAsImage: 'global_save_as_image',
    saveAsCSV: 'global_save_as_csv',
    locationsTitle: 'filter_location_title',
    yearsTitle: 'filter_year_title',
    metricsTitle: 'filter_metric_title',
    ageGroupsTitle: 'filter_age_group_title',
    chartTitle: 'breakdown_scene_chart_title',
    chartSubTitle: 'breakdown_scene_chart_sub_title',
};
