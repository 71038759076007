import PropTypes from 'prop-types';
import React from 'react';
import Radium from 'radium';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import {
    Nav, Navbar, NavItem, NavDropdown,
} from '@ihme/common/web/components';

const propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            action: PropTypes.string,
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
    style: PropTypes.object,
};

class View extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: window.innerWidth,
        };

        this.onResize = this.onResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        this.setState({ windowWidth: window.innerWidth });
    }

    renderNavigation = () => (
        <Navbar.Collapse>
            <Nav pullRight>
                {this.props.items.map(item => (item.subItems ? this.renderDropDown(item) : this.renderNavItem(item)))}
            </Nav>
        </Navbar.Collapse>
    );

    renderDropDown = menu => (
        <NavDropdown id="nav-dropdown" key={menu.key} title={menu.title}>
            {menu.subItems.map(item => this.renderNavItem(item))}
        </NavDropdown>
    );

    renderNavItem = item => {
        const isHttpLink = item.action.indexOf('http://') !== -1 || item.action.indexOf('https://') !== -1;

        return isHttpLink ? (
            <NavItem disabled={item.disabled} key={item.key} href={item.action} target="_blank">
                {item.title}
            </NavItem>
        ) : (
            <LinkContainer exact key={item.key} to={item.action}>
                <NavItem>{item.title}</NavItem>
            </LinkContainer>
        );
    };

    render() {
        return (
            <Navbar collapseOnSelect style={this.props.style}>
                <Navbar.Header>
                    <Navbar.Brand>
                        <Link to="/">
                            <img style={styles.logo} src="/img/logo-32x32.png" alt="IHME Hepatitis Facts" />
                            {this.state.windowWidth < 1200 && this.state.windowWidth >= 992 ? 'IHME' : 'IHME Hepatitis Facts'}
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>{this.renderNavigation()}</Navbar.Collapse>
            </Navbar>
        );
    }
}

const styles = {
    logo: {
        display: 'inline',
        marginRight: 7,
        marginLeft: 15,
        height: 26,
        marginTop: -6,
        /* sm */
        '@media (min-width: 768px)': {
            height: 26,
        },
        /* md */
        '@media (min-width: 992px)': {
            height: 26,
        },
    },
};

View.propTypes = propTypes;

export default Radium(View);
