import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

const propTypes = {
    children: PropTypes.any,
    style: PropTypes.object,
};

const defaultProps = {
    style: {},
};

// Please don't change it to h4, it's page header h1
const PageHeader = props =>
    <h1 style={{ ...styles.container, ...props.style }}>
        {props.children}
    </h1>;

const styles = {
    container: {
        paddingLeft: 15,
        fontSize: 40,
        color: '#333333',
        marginBottom: 50,
        '@media (max-width: 768px)': {
            marginBottom: 20,
        },
    },
};

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;

export default Radium(PageHeader);
