import React from 'react';
import { debounce, zip, sortBy } from 'lodash';
import numeral from 'numeral';
import Echarts from 'react-echarts-v3';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';

import styled from '@ihme/common/theme/styled';
import _ from '@ihme/common/locale';
import locale from './locale';

import echartsTheme from '../../../theme/echartsTheme';

const MOBILE_VIEW_HEIGHT = 993;

const View = styled('div')(() => ({}));

type DataModel = {
    id: number,
    chartId: number,
    name: string,
    value: string,
    itemStyle: { color: string },
}

type Filters = {
    year: number,
    metric_id: number,
    age_group_id: number,
    measure_id: number | string,
    location_id: number | string,
    cause_id: number | string,
    gender_id: number | string,
}

type Props = {
    isLoading: boolean,
    filters: Filters,
    chartsIds: Array<number>,
    chartsSeries: Array<[number, Array<DataModel | undefined>]>,
}

type State = {
    windowWidth: number,
}

class BarChart extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            windowWidth: window.innerWidth,
        };

        this.onResize = debounce(this.onResize.bind(this), 250);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        this.setState({ windowWidth: window.innerWidth });
    }

    isMobileView = () => {
        return this.state.windowWidth < MOBILE_VIEW_HEIGHT;
    }

    getHeight = () => {
        if (this.isMobileView()) {
            return 800;
        }
        return 400;
    }

    getGridPositions = () => {
        if (this.isMobileView()) {
            return [
                { left: 50, top: '8%', bottom: '69%' },
                { left: 50, top: '43%', bottom: '34%' },
            ];
        }
        return [
            { right: '53%', bottom: '25%' },
            { left: '53%', bottom: '25%' },
        ];
    }

    getTitlesPositions = () => {
        const { metric_id } = this.props.filters;
        if (this.isMobileView()) {
            return [{
                top: '0%',
                left: 'center',
                text: _(locale.prevalence_title),
                subtext: _('metric_' + metric_id),
            }, {
                top: '35%',
                left: 'center',
                text: _(locale.incidence_title),
                subtext: _('metric_' + metric_id),
            }];
        }

        return [{
            top: '0%',
            left: '14%',
            text: _(locale.prevalence_title),
        }, {
            top: '5%',
            left: '24%',
            subtext: _('metric_' + metric_id),
        }, {
            top: '0%',
            left: '57%',
            text: _(locale.incidence_title),
        }, {
            top: '5%',
            left: '67%',
            subtext: _('metric_' + metric_id),
        }];
    }

    getLocations = () => {
        const { filters } = this.props;

        return filters.location_id.toString()
            .split(',').map(id => _(`location_${id}`));
    }

    getSeriesLabels = () => {
        const { cause_id } = this.props.filters;

        return cause_id.toString()
            .split(',')
            .map(item => ({
                name: _(`cause_${item}_previnc`),
                icon: 'circle',
            }))
    }

    getSeriesData = () => {
        const { chartsIds, chartsSeries } = this.props;

        const seriesRecords = chartsSeries
            .map(([_, records]) => records)
            .filter(records => records && records[0] != null)
            .map(records =>
                sortBy(records, record => record.sortWeight).reverse()
            );

        const series = zip(...seriesRecords).map(records => ({
            type: 'bar',
            name: records[0].name,
            itemStyle: records[0].itemStyle,
            barCategoryGap: 10,
            stack: chartsIds.indexOf(records[0].chartId).toString(),
            xAxisIndex: chartsIds.indexOf(records[0].chartId),
            yAxisIndex: chartsIds.indexOf(records[0].chartId),
            data: records,
        }));

        return series;
    }

    render() {
        const {
            isLoading,
        } = this.props;

        const xAxisLocations = this.getLocations();

        return (
            <View>
                <Echarts
                    notMerge
                    resizable
                    loading={isLoading}
                    optsLoading={echartsTheme.loading}
                    theme={echartsTheme}
                    style={{ height: this.getHeight() }}
                    option={{
                        legend: {
                            orient: 'horizontal',
                            x: 'center',
                            y: 'bottom',
                            width: '90%',
                            textStyle: echartsTheme.textStyle,
                            data: this.getSeriesLabels(),
                        },
                        tooltip: {
                            ...echartsTheme.tooltip,
                            trigger: 'item',
                            formatter: (params: any) =>
                                `${params.name}<br/><b>${numeral(params.value)
                                    .format('0,0.0')}</b>`,
                        },
                        grid: this.getGridPositions(),
                        title: this.getTitlesPositions(),
                        xAxis: [{
                            data: xAxisLocations,
                            ...echartsTheme.xAxis,
                            axisTick: { show: false },
                            splitLine: { show: false },
                            gridIndex: 0,
                        }, {
                            data: xAxisLocations,
                            ...echartsTheme.xAxis,
                            axisTick: { show: false },
                            splitLine: { show: false },
                            gridIndex: 1,
                        }],
                        yAxis: [{
                            ...echartsTheme.yAxis,
                            axisTick: { show: false },
                            axisLabel: {
                                ...echartsTheme.textStyle,
                                formatter: (value: string) => numeral(value)
                                    .format('0,0')
                                    .toUpperCase(),
                            },
                            gridIndex: 0,
                        }, {
                            ...echartsTheme.yAxis,
                            axisTick: { show: false },
                            axisLabel: {
                                ...echartsTheme.textStyle,
                                formatter: (value: string) => numeral(value)
                                    .format('0,0')
                                    .toUpperCase(),
                            },
                            gridIndex: 1,
                        }],
                        series: this.getSeriesData(),
                    }}
                />
            </View>
        );
    }
}

export default BarChart;
