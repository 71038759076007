import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import query from 'query-string';
import intersection from 'lodash/intersection';

import { loadFactsFilters, loadCountries } from '../../actions/data-actions';
import { getFactsFilters, getCountries } from '../../reducers';

import config from '../../config';

import Container from './Container';

const propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,

    loadFilters: PropTypes.func.isRequired,
    filters: PropTypes.object,

    loadCountries: PropTypes.func.isRequired,
    countries: PropTypes.array,
};

const mapStateToProps = store => ({
    filters: getFactsFilters(store),
    countries: getCountries(store),
});

class FactsheetsScene extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = this.getStateFromQueryString(props.location.search);
    }

    componentWillMount() {
        if (this.props.filters == null) {
            this.props.loadFilters();
        }
        if (this.props.countries == null) {
            this.props.loadCountries();
        }
    }

    componentWillReceiveProps(nextProps) {
        const nextQueryString = nextProps.location.search;
        if (nextQueryString !== this.props.location.search) {
            this.setState(
                this.getStateFromQueryString(nextQueryString));
        }
    }

    getStateFromQueryString(queryString) {
        const params = query.parse(queryString);

        return {
            locationId: params.location_id
                ? parseInt(params.location_id)
                : config.defaultLocationId,
        };
    }

    getAvailableLocations = () => {
        const { filters } = this.props;
        const { locationId } = this.state;

        const filterLocations = filters ? filters.location_id : [locationId];
        const countries = this.props.countries || [{ id: locationId }];

        return intersection(filterLocations, countries.map(country => country.id));
    };

    render() {
        return (
            <Container
                history={this.props.history}
                location={this.props.location}
                locations={this.getAvailableLocations()}
                locationId={this.state.locationId}
            />
        );
    }
}

FactsheetsScene.propTypes = propTypes;

export default withRouter(connect(mapStateToProps, {
    loadCountries,
    loadFilters: loadFactsFilters,
})(FactsheetsScene));
