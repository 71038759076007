import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import _ from '@ihme/common/locale';
import locale from './locale';

const propTypes = {
    style: PropTypes.object,
};

const defaultProps = {
    style: {},
};

const Footer = props =>
    <div style={{ ...styles.container, ...props.style }}>
        <p style={styles.text} dangerouslySetInnerHTML={{ __html: _(locale.footer) }} />
    </div>;

const styles = {
    container: {
        paddingLeft: 15,
        paddingRight: 15,
        background: '#F5F7F8',
        textAlign: 'center',
        height: 90,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        marginBottom: 0,
    },
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Radium(Footer);
