import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import _ from '@ihme/common/locale';
import locale from './locale';

const propTypes = {
    locationId: PropTypes.number.isRequired,
    globalData: PropTypes.array.isRequired,
    locationData: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default class View extends React.PureComponent {
    formatValue(value) {
        return numeral(value)
            .format('0.00 a')
            .toUpperCase();
    }

    render() {
        // @todo: add loading indicator
        return (
            <article style={styles.container}>
                <section style={styles.sectionContainer}>
                    <h2 style={styles.sectionHeader}>{_('location_1')}</h2>
                    {this.props.globalData.map(data =>
                        <div key={data.year + ' ' + data.value}>
                            <p style={{}}>
                                <b>{data.year}</b><br />
                                {_(locale.deathsAmount, { amount: this.formatValue(data.value) })}
                            </p>
                        </div>,
                    )}
                </section>

                <section style={styles.sectionContainer}>
                    <h2 style={styles.sectionHeader}>{_(`location_${this.props.locationId}`)}</h2>
                    {this.props.locationData.map(data =>
                        <div key={data.year + ' ' + data.value}>
                            <p style={{}}>
                                <b>{data.year}</b><br />
                                {_(locale.deathsAmount, { amount: this.formatValue(data.value) })}
                            </p>
                        </div>,
                    )}
                </section>
            </article>
        );
    }
}

const styles = {
    container: {
        backgroundColor: '#F5F7F8',
        padding: 10,
    },
    sectionContainer: {
        padding: 20,
    },
    sectionHeader: {
        marginTop: 0,
        marginBottom: 10,
        color: 'green',
    },
};

View.propTypes = propTypes;
