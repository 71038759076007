import React from 'react';
import PropTypes from 'prop-types';

import { Col, Clearfix } from '@ihme/common/web/components';

import Highlight from './Highlight';
import Chart from './Chart';

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
    locationId: PropTypes.number.isRequired,
    data: PropTypes.object,
};

const View = props =>
    <div>
        <Col xs={12} sm={9} smPush={3}>
            <Chart
                locationId={props.locationId}
                rowData={props.data}
                isLoading={props.isLoading}
            />
        </Col>
        <Col xs={12} sm={3} smPull={9}>
            <Highlight
                locationId={props.locationId}
                rowData={props.data}
                isLoading={props.isLoading}
            />
        </Col>
        <Clearfix/>
    </div>;

View.propTypes = propTypes;

export default View;
