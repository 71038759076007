import React from 'react';
import PropTypes from 'prop-types';

import Col from 'react-bootstrap/lib/Col';
import Clearfix from 'react-bootstrap/lib/Clearfix';

import Highlight from './Highlight';
import Chart from './Chart';

const propTypes = {
    locationId: PropTypes.number.isRequired,
};

const Deaths = props =>
    <div>
        <Col xs={12} sm={9} smPush={3}>
            <Chart locationId={props.locationId}/>
        </Col>
        <Col xs={12} sm={3} smPull={9}>
            <Highlight locationId={props.locationId}/>
        </Col>
        <Clearfix/>
    </div>;

Deaths.propTypes = propTypes;

export default Deaths;
