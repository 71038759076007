import React from 'react';
import numeral from 'numeral';

import _ from '@ihme/common/locale';

import api from '../../../api';
import config from '../../../config';
import echartsTheme from '../../../theme/echartsTheme';

import BarChart from './BarChart';

const COLORS = echartsTheme.bar.seriesColorSpectrum;
const CAUSES_ORDER = [401, 402, 522, 418, 18673, 403, 523, 419, 2994, 404];
const PREVALENCE_CAUSES = [401, 402, 522, 418, 403, 523, 419, 404];
const INCIDENCE_CAUSES = [401, 402, 418, 2994, 403, 419, 18673, 404];

const DEFAULT_FILTERS = {
    cause_id: config.factsheetPrevalenceIncidenceCauses.join(','),
    measure_id: `${config.prevalenceMeasureId},${config.incidenceMeasureId}`,
    age_group_id: config.allAgesGroupId,
    gender_id: config.bothGendersId,
    metric_id: 3,
};

type Props = {
    locationId: number,
    year: number,
};

type State = {
    isLoading: boolean,
    errors: {} | undefined,
    globalData: Array<any>,
    locationData: Array<any>,
    globalIncidenceData: Array<any>,
    locationIncidenceData: Array<any>,
}

class PrevalenceIncidence extends React.PureComponent<Props, State> {
    static defaultProps = {
        year: config.latestYear,
    };

    mounted = false;
    state: State = {
        isLoading: false,
        errors: undefined,
        globalData: [],
        locationData: [],
        globalIncidenceData: [],
        locationIncidenceData: [],
    };

    componentDidMount() {
        this.mounted = true;
        this.loadData();
    }

    componentDidUpdate(prevProps: Props) {
        const { locationId } = this.props;
        if (locationId !== prevProps.locationId) {
            this.loadData();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setStateIfMounted: this['setState'] = (state, callback) => {
        if (this.mounted) {
            this.setState(state, callback);
        }
    }

    getFilters = () => {
        const { locationId } = this.props;

        return {
            ...DEFAULT_FILTERS,
            year: this.props.year,
            location_id: `${config.globalLocationId},${locationId}`,
        };
    }

    loadData = () => {
        this.setStateIfMounted({
            isLoading: true,
            errors: undefined,
        });

        api.data.getCauseOutcome(this.getFilters())
            .then(response =>
                this.setStateIfMounted({
                    isLoading: false,
                    globalData: this.parseResponse(response, config.globalLocationId),
                    locationData: this.parseResponse(response, this.props.locationId),
                }))
            // @todo: add errors catching
            .catch(console.log);
    }

    parseResponse(response, locationId) {
        const { columns, records } = response;
        const valueIndex = columns.indexOf('value');
        const locationIndex = columns.indexOf('location_id');
        const causeIndex = columns.indexOf('cause_id');
        const measureIndex = columns.indexOf('measure_id');

        const getChartId = (causeId, measureId) => {
            if (measureId === config.prevalenceMeasureId && PREVALENCE_CAUSES.includes(causeId)) {
                return measureId;
            }

            if (measureId === config.incidenceMeasureId && INCIDENCE_CAUSES.includes(causeId)) {
                return measureId;
            }

            return null;
        };

        return records
            .filter(record =>
                record[locationIndex] === locationId
            )
            .map(record => {
                const causeId = record[causeIndex];
                const measureId = record[measureIndex];

                return {
                    id: causeId,
                    chartId: getChartId(causeId, measureId),
                    name: _(`cause_${causeId}_previnc`),
                    value: numeral(record[valueIndex])
                        .format('0.0'),
                    itemStyle: {
                        color: COLORS[CAUSES_ORDER.indexOf(causeId)],
                    },
                    sortWeight: CAUSES_ORDER.indexOf(causeId),
                };
            })
            .filter(record =>
                record.chartId !== null
            );
    }

    render() {
        return (
            <BarChart
                isLoading={this.state.isLoading}
                filters={this.getFilters()}
                chartsIds={[config.prevalenceMeasureId, config.incidenceMeasureId]}
                chartsSeries={[
                    [config.globalLocationId, this.state.globalData],
                    [this.props.locationId, this.state.locationData],
                ]}
            />
        );
    }
}

export default PrevalenceIncidence;
