import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';

const propTypes = {
    isMulti: PropTypes.bool,
    isLoading: PropTypes.bool,
    autoFocus: PropTypes.bool,
    required: PropTypes.bool,
    isClearable: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    onChange: PropTypes.func.isRequired,
};

const customComponents = {
    DropdownIndicator: (props) => {
        return components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <img src='/svg/dropdown-indicator.svg' />
            </components.DropdownIndicator>
        );
    },
};

const customStyles = {
    container: styles => ({
        ...styles,
        display: 'flex',
        flexDirection: 'column',
    }),
    multiValue: ({ backgroundColor, ...styles }) => ({
        ...styles,
    }),
    multiValueLabel: styles => ({
        ...styles,
        paddingLeft: 0,
        paddingRight: 0,
        fontSize: '100%',
    }),
    multiValueRemove: styles => ({
        ...styles,
        fontSize: '100%',
        paddingLeft: 0,
        paddingRight: 0,
        marginRight: 3,
        ':hover': {
            opacity: 0.7,
            boxShadow: 'inset 0 -1px 0 0 #DDE3E6',
        },
    }),
    indicatorSeparator: (base, state) => ({}),
    dropdownIndicator: (base, state) => ({
        ...base,
        paddingRight: 16,
    }),
    control: (props, state) => ({
        ':hover': {
            backgroundImage: 'linear-gradient(-180deg, #ECEFF1 2%, #D7DEE2 92%)',
            boxShadow: 'inset 0 -1px 0 0 #C2C9CC',
        },
        ':active': {
            background: '#C2C9CC',
            boxShadow: 'unset',
        },
        backgroundImage: 'linear-gradient(-180deg, #F5F7F8 2%, #ECEFF1 97%)',
        boxShadow: 'inset 0 -1px 0 0 #DDE3E6',
        borderRadius: 3,
        alignItems: props.alignItems,
        boxSizing: props.boxSizing,
        cursor: 'pointer',
        fontFamily: 'Helvetica, sans-serif',
        letterSpacing: 0,
        display: props.display,
        flexWrap: props.flexWrap,
        justifyContent: props.justifyContent,
        minHeight: props.minHeight,
        outline: props.outline,
        position: props.position,
        transition: props.transition,
    }),
    menu: (props) => ({
        ...props,
        background: '#F5F7F8',
        boxShadow: 'inset 0 -1px 0 0 #DDE3E6',
        borderRadius: 3,
        marginTop: 0,
    }),
    menuList: (props) => ({
        ...props,
        paddingTop: 0,
    }),
    option: (props, state) => {
        return {
            ...props,
            cursor: 'pointer',
            fontFamily: 'Helvetica, sans-serif',
            background: state.isSelected ? '#E2E4E6' : 'transparent',
            color: '#333',
            ':hover': {
                background: state.isSelected ? '#E2E4E6' : '#EAECEE',
            },
        };
    },
};

class Select extends React.Component {
    reactSelectRef = React.createRef();

    componentDidMount() {
        const select = this.reactSelectRef.current.select;
        if (!select.onInputBlurPatched) {
            const originalOnInputBlur = select.onInputBlur;
            select.onInputBlur = e => {
                if (select.menuListRef && select.menuListRef.contains(document.activeElement)) {
                    select.inputRef.focus();
                    return;
                }
                originalOnInputBlur(e);
            }
            select.onInputBlurPatched = true;
        }
    }

    handleChange = value => {
        const { name } = this.props;
        this.props.onChange({ target: { name, value } });
    }

    render() {
        const {
            isMulti = false,
            isLoading = false,
            autoFocus = false,
            required = false,
            isClearable = false,
            name,
            placeholder,
            options,
            value,
        } = this.props;

        return (
            <ReactSelect
                {...this.props}
                ref={this.reactSelectRef}
                isMulti={isMulti}
                isLoading={isLoading}
                autoFocus={autoFocus}
                required={required}
                isClearable={isClearable}
                name={name}
                placeholder={placeholder}
                options={options}
                value={value}
                styles={customStyles}
                components={customComponents}
            />
        );
    }
}

Select.propTypes = propTypes;

export default Select;

