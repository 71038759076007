import React from 'react';
import { Link } from 'react-router-dom';
import Radium from 'radium';

import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import _ from '@ihme/common/locale';
import locale from './locale';

class NewsList extends React.PureComponent {

    generateNews() {
        return [
            {
                key: 0,
                title: _(locale.link0),
                link: 'http://www.worldhepatitisalliance.org/world-hepatitis-day/world-hepatitis-day-2018',
            },
            {
                key: 1,
                title: _(locale.link1),
                link: 'http://apps.who.int/iris/bitstream/handle/10665/246177/WHO-HIV-2016.06-eng.pdf;jsessionid=5047F5D02C4220286DF35DE2DAE8D827?sequence=1',
            },
            {
                key: 2,
                title: _(locale.link2),
                link: 'http://www.healthdata.org/news-release/viral-hepatitis-joins-aids-malaria-and-tb-leading-cause-death',
            },
            {
                key: 3,
                title: _(locale.link3),
                link: 'https://jamanetwork.com/journals/jamaoncology/fullarticle/2656875',
            },
            {
                key: 4,
                title: _(locale.link4),
                link: 'http://ihmeuw.org/4f4j',
            },
        ];
    }

    render() {
        return (
            <React.Fragment>
                <h4 style={styles.header}>
                    {_(locale.newsTitle)}
                </h4>

                {this.generateNews()
                    .map(item =>
                        <a target="_blank" rel="noopener noreferrer" href={item.link} key={item.key}>
                            <div className="home_news_button">
                                <div>
                                    {item.title}
                                </div>
                                <div>
                                    <img style={styles.arrow} src="/img/ic-arrow.png" alt="Right Arrow" />
                                </div>
                            </div>
                        </a>
                    )}
            </React.Fragment>
        );
    }
}

const styles = {
    header: {
        marginTop: 80,
        marginBottom: 40,
        textAlign: 'left',
        '@media (max-width: 767px)': {
            marginTop: 40,
            marginBottom: 20,
        },
    },
    arrow: {
        paddingLeft: 10,
    },
};

export default Radium(NewsList);
