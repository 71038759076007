import React from 'react';
import { Location, RouterHistory, withRouter } from 'react-router';
import { compose } from 'redux';
import numeral from 'numeral';
import queryString from 'query-string';

import withQueryParams, {
    WithQueryParamsInjectedProps,
} from '@ihme/common/utility/hoc/with-query-params';
import { withStorage, WithStorageProps } from '@ihme/common/packages/storage';
import _ from '@ihme/common/locale';
import styled from '@ihme/common/theme/styled';
import {
    ChartProvider,
    ChartDropdown,
    Clearfix,
    Form,
    BarChart,
    Col,
    PageHeader,
} from '@ihme/common/web/components';

import locale from './locale';
import api from '../../api';
import config from '../../config';

import { sortLocationOptions } from '../../utility/sorting-helpers';

import echartsTheme from '../../theme/echartsTheme';
import { csvIcon, pngIcon } from '../../theme/icons';

import ChartHighlight from '../ChartHighlight';

const ButtonGroup = styled.div(({ theme }) => ({
    '> *': {
        width: 140,
        marginRight: 12,
        [theme.breakpoint.xs]: {
            width: 220,
        },
    },
}));

const LocationsDropdown = styled(ChartDropdown)(({ theme }) => ({
    width: 420,
    [theme.breakpoint.xs]: {
        width: '100%',
    },
}));

const BarChartContainer = styled('div')({
    marginTop: 32,
    height: 420,
});

const DEFAULT_FILTERS = {
    year: config.latestYear,
    location_id: config.breakdownDefaultLocationIds.toString(),
    age_group_id: config.breakdownDefaultAgeGroupId,
    gender_id: config.bothGendersId,
    measure_id: config.prevalenceMeasureId,
    metric_id: config.breakdownDefaultMetricId,
    cause_id: config.breakdownCauses.join(','),
};

type Filters = typeof DEFAULT_FILTERS;

type Props = WithQueryParamsInjectedProps<Filters> & WithStorageProps & {
    location: Location;
    history: RouterHistory;
}

class BreakdownScene extends React.Component<Props> {
    getInitialFilters = () => queryString.parse(this.props.location.search);

    getSaveFilename = ({ filters }) => {
        const locations = filters.location_id
            .toString()
            .split(',')
            .map(locationId => _(`location_${locationId}`))
            .join(', ');

        return (
            'Breakdown' +
            ', ' +
            locations +
            ', ' +
            _(`age_group_${filters.age_group_id}`) +
            ', ' +
            _(`metric_${filters.metric_id}`) +
            ', ' +
            filters.year
        );
    };

    limitSelectedLocations = locationIds =>
        locationIds.slice(Math.max(locationIds.length - 3, 0));

    render() {
        const { history, location, cached } = this.props;

        return (
            <div>
                <PageHeader>{_(locale.title)}</PageHeader>
                <Col xs={12} md={9} mdPush={3}>
                    <ChartProvider
                        loadFilters={() => cached(api.data.getCauseOutcomeFilters)({
                            gender_id: config.bothGendersId,
                            measure_id: config.prevalenceMeasureId,
                            cause_id: config.breakdownCauses.join(','),
                        })}
                        loadData={filters => api.data.getCauseOutcome({
                            ...DEFAULT_FILTERS,
                            ...filters,
                        })}
                        defaultFilters={DEFAULT_FILTERS}
                        initialFilters={this.getInitialFilters()}
                        history={history}
                        location={location}>
                        <Form inline>
                            <ButtonGroup>
                                <LocationsDropdown
                                    label={_(locale.locationsTitle)}
                                    localePrefix="location_"
                                    filterKey="location_id"
                                    preprocessOptions={sortLocationOptions}
                                    preprocessChange={this.limitSelectedLocations}
                                    isSearchable
                                    isMulti
                                />
                                <ChartDropdown
                                    label={_(locale.ageGroupsTitle)}
                                    localePrefix="age_group_"
                                    filterKey="age_group_id"
                                />
                                <ChartDropdown
                                    label={_(locale.metricsTitle)}
                                    localePrefix="metric_"
                                    filterKey="metric_id"
                                />
                                <ChartDropdown
                                    label={_(locale.yearsTitle)}
                                    filterKey="year"
                                />
                            </ButtonGroup>
                            <Clearfix/>
                        </Form>

                        <BarChartContainer>
                            <BarChart
                                theme={echartsTheme}
                                configureGrid={({ filters, isMobileView }) => isMobileView
                                    ? [
                                        { bottom: '57%' },
                                    ]
                                    : [
                                        { bottom: '30%' }
                                    ]
                                }
                                configureLegend={() => ( {
                                    orient: 'horizontal',
                                    x: 'center',
                                    top: 'bottom',
                                    width: '90%',
                                    textStyle: echartsTheme.textStyle,
                                } )}
                                configureTitles={({
                                                      filters: { location_id, age_group_id, year },
                                                      isMobileView,
                                                  }) => {
                                    const text = _(locale.chartTitle, {
                                        location: location_id
                                            .toString()
                                            .split(',')
                                            .map(locationId =>
                                                _(`location_${locationId}`)
                                            )
                                            .join(', '),
                                    });
                                    const textProps = {
                                        text,
                                        textStyle: {
                                            fontSize: isMobileView ? 15 : 20,
                                        },
                                        subtext: _(locale.chartSubTitle, {
                                            ageGroup: _('age_group_' + age_group_id),
                                            year,
                                        }),
                                    };

                                    if (isMobileView) {
                                        return [
                                            {
                                                top: '0%',
                                                left: '0%',
                                                ...textProps,
                                            }
                                        ];
                                    }
                                    return [
                                        {
                                            top: '0%',
                                            left: 'center',
                                            ...textProps,
                                        },
                                    ];
                                }}
                                renderTooltip={params => `${params.name}<br/>
                        <b>${numeral(params.value).format('0,0.0')}</b>`}
                                xAxisKey="location_id"
                                formatXAxisLabel={id => _('location_' + id)}
                                yAxisKey="value"
                                formatYAxisLabel={value =>
                                    numeral(value)
                                        .format('0,0')
                                        .toUpperCase()
                                }
                                dataKey="cause_id"
                                multipleChartByKey="measure_id"
                                multipleChartByKeyIds={[config.prevalenceMeasureId]}
                                formatLegendLabel={id => _(`cause_${id}`)}
                                saveAsImage={{
                                    visible: true,
                                    filename: this.getSaveFilename,
                                    icon: pngIcon,
                                }}
                                saveAsCSV={{
                                    visible: true,
                                    filename: this.getSaveFilename,
                                    icon: csvIcon,
                                    headers: [
                                        'Location',
                                        'Year',
                                        'Age Group',
                                        'Cause',
                                        'Unit',
                                        'Value',
                                    ],
                                    keys: [
                                        'location_id',
                                        'year',
                                        'age_group_id',
                                        'cause_id',
                                        'measure_id',
                                        'value',
                                    ],
                                }}
                            />
                        </BarChartContainer>
                    </ChartProvider>
                </Col>
                <Col xs={12} md={3} mdPull={9}>
                    <ChartHighlight>
                        {_(locale.highlightText)}
                    </ChartHighlight>
                </Col>
            </div>
        );
    }
}

export default compose(
    withRouter,
    withStorage,
    withQueryParams,
)(BreakdownScene);
