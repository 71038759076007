import React from 'react';
import PropTypes from 'prop-types';

import numeral from 'numeral';

import api from '../../../api';
import config from '../../../config';

import _ from '@ihme/common/locale';

import View from './View';

const propTypes = {
    locationId: PropTypes.number.isRequired,
};

const COLORS = {
    401: '#9575CD',
    402: '#82DBA3',
    522: '#4CCC7C',
    418: '#20C05C',
    902: '#199949',
    403: '#FBDD39',
    523: '#F7B600',
    419: '#F18602',
    903: '#E86D0C',
    404: '#8D6E63',
};

export default class Breakdown extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            globalData: [],
            locationData: [],
            errors: undefined,
        };

        this.mounted = false;

        this.setStateIfMounted = this.setStateIfMounted.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        this.loadData(this.props.locationId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.locationId !== this.props.locationId) {
            this.loadData(nextProps.locationId);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setStateIfMounted(state, callback) {
        if (this.mounted) {
            this.setState(state, callback);
        }
    }

    loadData(locationId) {
        this.setStateIfMounted({
            isLoading: true,
            errors: undefined,
        });

        const criteria = {
            location_id: config.globalLocationId + ',' + locationId,
            age_group_id: config.allAgesGroupId,
            gender_id: config.bothGendersId,
            measure_id: config.dalyMeasureId,
            metric_id: config.numberMetricId,
            year: config.latestYear,
            cause_id: config.breakdownCauses.join(','),
        };

        api.data.getCauseOutcome(criteria)
            .then(response =>
                this.setStateIfMounted({
                    isLoading: false,
                    globalData: this.parseResponse(response, config.globalLocationId),
                    locationData: this.parseResponse(response, locationId),
                }))
            // @todo: add errors catching
            .catch(console.log);
    }

    parseResponse(response, locationId) {
        const { columns, records } = response;
        const valueIndex = columns.indexOf('value');
        const locationIndex = columns.indexOf('location_id');
        const causeIndex = columns.indexOf('cause_id');

        return records.filter(record =>
            record[locationIndex] === locationId)
            .map(record => {
                const id = record[causeIndex];
                return {
                    id,
                    name: _(`cause_${id}`),
                    value: numeral(record[valueIndex])
                        .format('0.0'),
                    itemStyle: { color: COLORS[id] },
                };
            });
    }


    render() {
        const {
            isLoading,
            globalData,
            locationData,
        } = this.state;

        return (
            <View
                isLoading={isLoading}
                globalData={globalData}
                locationData={locationData}
                locationId={this.props.locationId}
            />
        );
    }
}

Breakdown.propTypes = propTypes;
