import React from 'react';
import sortBy from 'lodash/sortBy';

import api from '../../../../api';
import config from '../../../../config';
import flatten from 'lodash/flatten';

import _ from '@ihme/common/locale';
import locale from '../locale';

import View from './View';
import PropTypes from 'prop-types';

const propTypes = {
    locationId: PropTypes.number.isRequired,
};

const COLORS = {
    '400,418,419,522,523': '#E86D0C',
    297: '#50C3F7',
    298: '#199949',
    345: '#FBDD39',
};
export default class DeathsChart extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            data: [],
            errors: undefined,
        };

        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
        this.loadData(this.props.locationId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.locationId !== this.props.locationId) {
            this.loadData(nextProps.locationId);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setStateIfMounted = (state, callback) => {
        if (this.mounted) {
            this.setState(state, callback);
        }
    };

    loadData = locationId => {
        this.setStateIfMounted({
            isLoading: true,
            error: undefined,
        });

        const locationIds = [config.globalLocationId, locationId];
        const causeIds = [
            config.tuberculosisCauseId,
            config.hivAidsCauseId,
            config.malariaCauseId,
            config.hepatitisCauseId,
        ];

        const criteria = {
            location_id: locationIds.join(','),
            age_group_id: config.allAgesGroupId,
            gender_id: config.bothGendersId,
            measure_id: config.deathMeasureId,
            metric_id: config.numberMetricId,
            cause_id: causeIds.join(','),
        };
        api.data.getCauseOutcome(criteria)
            .then(response => {
                const data = flatten(locationIds.map((location, i) =>
                    causeIds.map(causeId =>
                        this.parseResponse(response, causeId, location, i)))
                );

                this.setStateIfMounted({
                    data,
                    isLoading: false,
                });
            })// @todo: add errors catching
            .catch(console.log);
    };

    parseResponse(response, causeId, locationId, axisId) {
        const {
            columns,
            records,
        } = response;

        const yearIndex = columns.indexOf('year');
        const valueIndex = columns.indexOf('value');
        const causeIndex = columns.indexOf('cause_id');
        const locationIndex = columns.indexOf('location_id');

        return {
            id: causeId,
            color: COLORS[causeId],
            name: _(`cause_${causeId}`),
            locationId,
            axisId,
            values: sortBy(records, yearIndex)
                .filter(record =>
                    record[causeIndex] === causeId && record[locationIndex] === locationId
                )
                .map(record => ({
                    year: record[yearIndex],
                    value: record[valueIndex],
                    locationId,
                })),
        };
    }

    render() {
        const {
            data,
            isLoading,
        } = this.state;

        return (
            <View
                title={_(locale.chartTitle)}
                data={data}
                isLoading={isLoading}
                locationId={this.props.locationId}
            />
        );
    }
}

DeathsChart.propTypes = propTypes;
