import PropTypes from 'prop-types';

export const localeTypes = PropTypes.shape({
    chartTitle: PropTypes.string.isRequired,
});

export default {
    chartTitle: 'factsheets_scene_yll_chart_title',
    chartSubTitle: 'factsheets_scene_yll_chart_subtitle',
};
