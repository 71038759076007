import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import Icon from 'react-icons-kit';
import { download } from 'react-icons-kit/fa';

import _ from '@ihme/common/locale';
import locale from './locale';

import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';

import DropdownWithLabel from '../DropdownWithLabel';
import YLL from './YLL';
import Deaths from './Deaths';
import Breakdown from './Breakdown';
import PrevalenceIncidence from './PrevalenceIncidence';

const propTypes = {
    locations: PropTypes.array.isRequired,
    selectedLocationId: PropTypes.number.isRequired,
    onLocation: PropTypes.func.isRequired,
    onScreenshot: PropTypes.func.isRequired,
};

class View extends React.PureComponent {
    constructor(props) {
        super(props);

        this.viewRef = null;
    }

    render() {
        const {
            selectedLocationId,
            locations,
            onLocation,
            onScreenshot,
        } = this.props;

        return (
            <div ref={ref => this.viewRef = ref}>
                <header style={styles.header}>
                    <Row>
                        <Col xs={10}>
                            <h1 style={styles.headerTitle}>{_(locale.title)}</h1>
                            <DropdownWithLabel
                                style={styles.headerDropdown}
                                localeKeyPrefix="location_"
                                items={locations}
                                selectedId={selectedLocationId}
                                onSelect={onLocation}
                                isSearchable
                            />
                        </Col>
                        <Col xs={2}>
                            <Button
                                className="btn btn-default"
                                style={styles.screenshotBtn}
                                onClick={() => onScreenshot(this.viewRef)}>
                                <Icon size={14} icon={download}/>
                            </Button>
                        </Col>
                    </Row>
                    <p style={styles.subtitle}>{_(locale.subTitle)}</p>
                </header>

                <section style={styles.section}>
                    <YLL locationId={selectedLocationId}/>
                </section>
                <section style={styles.section}>
                    <Deaths locationId={selectedLocationId}/>
                </section>
                <section style={styles.section}>
                    <Breakdown locationId={selectedLocationId}/>
                </section>
                <section style={styles.section}>
                    <PrevalenceIncidence locationId={selectedLocationId}/>
                </section>

                {/* required for snapshot correct bottom padding */}
                <div style={styles.snapshotHelper}/>
            </div>
        );
    }
}

const styles = {
    header: {
        marginLeft: 15,
    },
    headerTitle: {
        marginRight: 19,
        fontSize: 40,
        display: 'inline',
    },
    headerDropdown: {
        display: 'inline-block',
        width: 185,
        marginTop: 6,
        marginBottom: 0,
        verticalAlign: 'text-bottom',
    },
    screenshotBtn: {
        marginTop: 6,
        marginBottom: 0,
    },
    subtitle: {
        marginTop: 6,
        marginBottom: 75,
        '@media (max-width: 768px)': {
            marginBottom: 20,
        },
    },
    section: {
        marginBottom: 80,
        '@media (max-width: 768px)': {
            marginBottom: 28,
        },
    },
    snapshotHelper: {
        height: 1,
        width: '100%',
        backgroundColor: 'transparent',
    },
};

View.propTypes = propTypes;

export default Radium(View);
