import { combineReducers } from 'redux';

import data, * as dataSelectors from './data-reducer';

export default combineReducers({
    data,
});

export const getTrendsFilters = store => dataSelectors.getTrendsFilters(store.data);
export const getExtendedCauses = store => dataSelectors.getExtendedCauses(store.data);
export const getGlobalImpactFilters = store => dataSelectors.getGlobalImpactFilters(store.data);
export const getBreakdownFilters = store => dataSelectors.getBreakdownFilters(store.data);
export const getFactsFilters = store => dataSelectors.getFactsFilters(store.data);
export const getGlobalYllData = store => dataSelectors.getGlobalYllData(store.data);
export const getCountries = store => dataSelectors.getCountries(store.data);
