import PropTypes from 'prop-types';

export const localeTypes = PropTypes.shape({
    title: PropTypes.string.isRequired,
    deathsAmount: PropTypes.string.isRequired,
});

export default {
    title: 'factsheets_scene_deaths_highlight_title',
    deathsAmount: 'factsheets_scene_deaths_highlight_deaths',
};
