export default {
    home: 'navigation_home',
    impact: 'navigation_impact',
    trends: 'navigation_trends',
    breakdown: 'navigation_breakdown',
    factsheets: 'navigation_factsheets',
    about: 'navigation_about',
    moreStories: 'navigation_more_stories',
    hiv: 'navigation_hiv',
};
