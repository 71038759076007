import React from 'react';
import PropTypes from 'prop-types';

import View from './View';

const propTypes = {
    locationId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,

    rowData: PropTypes.object,
};

export default class Highlight extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            lowestLocations: [],
            highestLocations: [],
            selectedLocationValue: 0,
            ...this.getStateFromProps(props),
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState(this.getStateFromProps(nextProps));
    }

    getStateFromProps = props =>
        props.rowData
            ? this.parseResponse(props.locationId, props.rowData)
            : null;

    parseResponse = (locationId, response) => {
        const { columns, records } = response;
        const valueIndex = columns.indexOf('value');
        const locationIndex = columns.indexOf('location_id');

        const selectedLocationValue = records.filter(record =>
            record[locationIndex] === locationId)[0][valueIndex];
        const locations = records
            .sort((a, b) =>
                a[valueIndex] - b[valueIndex])
            .map(record =>
                record[locationIndex]);

        return {
            selectedLocationValue,
            lowestLocations: locations.slice(0, 3),
            highestLocations: locations.slice(-3)
                .reverse(),
        };
    };

    render() {
        return (
            <View
                lowestLocations={this.state.lowestLocations}
                highestLocations={this.state.highestLocations}
                locationValue={this.state.selectedLocationValue}
                locationId={this.props.locationId}
                isLoading={this.props.isLoading}
            />
        );
    }
}

Highlight.propTypes = propTypes;
