import PropTypes from 'prop-types';

export const localeTypes = PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
});

export default {
    title: 'about_scene_title',
    text: 'about_scene_text',
};
