import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    children: PropTypes.string,
};

const ChartHighlight = props =>
    <p
        style={styles.container}
        dangerouslySetInnerHTML={{ __html: props.children }}
    />;

const styles = {
    container: {
    },
};

ChartHighlight.propTypes = propTypes;

export default ChartHighlight;
