import React from 'react';
import Radium from 'radium';

import Grid from 'react-bootstrap/lib/Grid';

import _ from '@ihme/common/locale';
import locale from './locale';
import HighlightItem from './HighlightItem';

class HighlightsList extends React.PureComponent {
    generateData() {
        return [
            {
                key: 0,
                iconSrc: '/img/what_is_hepatitis.jpg',
                title: _(locale.whatIsHepTitle),
                description: _(locale.whatIsHepDescription),
                text: _(locale.whatIsHepText),
            },
            {
                key: 1,
                iconSrc: '/img/global_goals.jpg',
                title: _(locale.globalGoalsTitle),
                description: _(locale.globalGoalsDescription),
                text: _(locale.globalGoalsText),
            },
            {
                key: 2,
                iconSrc: '/img/burden_and_risks.jpg',
                title: _(locale.burdenRiskTrendsTitle),
                description: _(locale.burdenRiskTrendsDescription),
                text: _(locale.burdenRiskTrendsText),
            },
        ];
    }

    render() {
        return (
            <Grid>
                <h4 style={styles.header}>
                    {_(locale.title)}
                </h4>

                {this.generateData()
                    .map(item =>
                        <HighlightItem
                            key={item.key}
                            description={item.description}
                            text={item.text}
                            title={item.title}
                            iconSrc={item.iconSrc}
                        />
                    )}
            </Grid>
        );
    }
}

const styles = {
    header: {
        textAlign: 'left',
        marginTop: 80,
        '@media (max-width: 767px)': {
            marginTop: 20,
            marginBottom: 20,
        },
    },
};

export default Radium(HighlightsList);
