import config from '../../config';

export const prepareCauseFilters = filters => {
    if (filters == null) {
        return null;
    }

    const genders = [...filters.gender_id];
    if (genders
        && genders.length
        && genders.indexOf(config.maleGenderId) !== -1
        && genders.indexOf(config.femaleGenderId) !== -1) {
        genders.push(config.maleAndFemaleGenderId);
    }
    return {
        ...filters,
        gender_id: genders,
        cause_id: filters.cause_id.concat(config.trendsExtraCauses),
    };
};

// @deprecated Delete
export const prepareCauseHierarchy = causes => {
    if (causes == null) {
        return null;
    }

    const items = causes.concat([
        {
            id: config.hepatitisCauseId,
            type: 'cause',
            level: 2,
        },
        {
            id: config.hepatitisBCauseId,
            type: 'cause',
            level: 4,
            level3_parent_id: config.acuteHepatitisCauseId,
        },
        {
            id: config.hepatitisCCauseId,
            type: 'cause',
            level: 4,
            level3_parent_id: config.acuteHepatitisCauseId,
        },
    ]);

    const setHierarchyFor = (id, newLevel, newParentId) => {
        const foundIndex = items.findIndex(cause => cause.id === id);
        items[foundIndex] = {
            ...items[foundIndex],
            level: newLevel,
            [`level${newLevel - 1}_parent_id`]: newParentId,
        };
    };

    setHierarchyFor(config.acuteHepatitisBCauseId, 5, config.hepatitisBCauseId);
    setHierarchyFor(config.acuteHepatitisCCauseId, 5, config.hepatitisCCauseId);
    setHierarchyFor(config.liverCancerDueToHepBCauseId, 5, config.hepatitisBCauseId);
    setHierarchyFor(config.liverCancerDueToHepCCauseId, 5, config.hepatitisCCauseId);
    setHierarchyFor(config.cirrhosisAndLiverDiseasesDueToHepBCauseId, 5, config.hepatitisBCauseId);
    setHierarchyFor(config.cirrhosisAndLiverDiseasesDueToHepCCauseId, 5, config.hepatitisCCauseId);

    return items.map(cause => {
        let {
            level,
            level3_parent_id,
            level4_parent_id,
        } = cause;

        if (level4_parent_id === config.liverCancerDueToHepBCauseId
            || level4_parent_id === config.acuteHepatitisBCauseId
            || level3_parent_id === config.cirrhosisAndLiverDiseasesDueToHepBCauseId) {
            level4_parent_id = config.hepatitisBCauseId;
            level = 5;
        }
        else if (level4_parent_id === config.liverCancerDueToHepCCauseId
            || level4_parent_id === config.acuteHepatitisCCauseId
            || level3_parent_id === config.cirrhosisAndLiverDiseasesDueToHepCCauseId) {
            level4_parent_id = config.hepatitisCCauseId;
            level = 5;
        }

        return {
            ...cause,
            level,
            level2_parent_id: config.hepatitisCauseId,
            level4_parent_id,
        };
    });
};
