import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';

import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Select from '../Select';

import _ from '@ihme/common/locale';

const propTypes = {
    label: PropTypes.string,
    items: PropTypes.array.isRequired,
    selectedId: PropTypes.any.isRequired,
    onSelect: PropTypes.func.isRequired,
    localeKeyPrefix: PropTypes.string.isRequired,
    labelStyle: PropTypes.object,
    sortBy: PropTypes.string,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    shouldReturnObject: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isClearable: PropTypes.bool,
};

const defaultProps = {
    sortBy: 'label',
    style: {},
    labelStyle: {},
    shouldReturnObject: false,
};

const RadiumFormGroup = Radium(FormGroup);

class DropdownWithLabel extends React.PureComponent {
    render() {
        const {
            label,
            selectedId,
            onSelect,
            localeKeyPrefix,
            labelStyle,
            placeholder,
            style,
            shouldReturnObject,
            isSearchable,
            isClearable,
        } = this.props;

        let items = this.props.items;
        let selectedLabel = '';
        if (typeof items[0] === 'object') {
            selectedLabel = selectedId.name;
            items = items.map(item => ({
                ...item,
                value: item.id,
                label: item.name,
            }));
        }
        else {
            selectedLabel = _(localeKeyPrefix + selectedId);
            items = items.map(item => ({
                value: item,
                label: _(localeKeyPrefix + item),
            }));
        }

        if (this.props.sortBy !== '') {
            items = sortBy(items, this.props.sortBy);
        }

        return (
            <RadiumFormGroup style={{ ...styles.container, ...style }}>
                {label &&
                    <ControlLabel style={{ ...styles.label, ...labelStyle }}>
                        {label}
                    </ControlLabel>
                }
                <Select
                    isSearchable={isSearchable}
                    isClearable={isClearable}
                    placeholder={placeholder}
                    options={items}
                    value={{
                        value: selectedId,
                        label: selectedLabel,
                    }}
                    onChange={item => shouldReturnObject
                        ? onSelect(item)
                        : onSelect({ id: item.value })}
                />
            </RadiumFormGroup>
        );
    }
}

const styles = {
    container: {
        width: 140,
    },
    label: {
        opacity: '0.4',
        fontFamily: 'Helvetica, sans-serif',
        fontSize: 12,
        fontWeight: 300,
        color: '#333333',
        letterSpacing: 0,
        marginBottom: 2,
    },
};

DropdownWithLabel.propTypes = propTypes;
DropdownWithLabel.defaultProps = defaultProps;

export default Radium(DropdownWithLabel);
