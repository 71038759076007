import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import numeral from 'numeral';
import queryString from 'query-string';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

import _ from '@ihme/common/locale';
import styled from '@ihme/common/theme/styled';
import {
    ChartProvider,
    ChartDropdown,
    ChartHierarchicalDropdown,
    Clearfix,
    Col,
    Form,
    LineChart,
    PageHeader,
} from '@ihme/common/web/components';

import { loadExtendedCauses, loadTrendsFilters } from '../../actions/data-actions';
import { getTrendsFilters, getExtendedCauses } from '../../reducers';
import { prepareCauseFilters } from './data-helpers';
import api from '../../api';
import config from '../../config';
import locale from './locale';

import echartsTheme from '../../theme/echartsTheme';
import { pngIcon, csvIcon } from '../../theme/icons';

import { sortLocationOptions } from '../../utility/sorting-helpers';

const ButtonGroup = styled.div(({ theme }) => ({
    '> *': {
        width: 124,
        marginRight: 12,
        [theme.breakpoint.xs]: {
            width: 252,
        },
    },
}));

const CausesDropdown = styled(ChartHierarchicalDropdown)({
    width: 248,
});

const LocationsDropdown = styled(ChartDropdown)({
    minWidth: 186,
});

const DEFAULT_FILTERS = {
    age_group_id: config.defaultAgeGroupId,
    gender_id: config.trendsDefaultGenderId,
    measure_id: config.defaultMeasureId,
    metric_id: config.numberMetricId,
    cause_id: config.defaultCauseId,
    location_id: config.defaultLocationId,
};

const propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,

    filters: PropTypes.object,
    loadFilters: PropTypes.func.isRequired,

    extendedCauses: PropTypes.array,
    loadExtendedCauses: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    filters: getTrendsFilters(state),
    extendedCauses: getExtendedCauses(state),
});

class TrendsScene extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
            availableMeasures: [],
        };
    }

    componentWillMount() {
        if (this.props.filters == null) {
            this.props.loadFilters();
        }
        if (this.props.extendedCauses == null) {
            this.props.loadExtendedCauses();
        }
    }

    getInitialFilters = () => queryString.parse(this.props.location.search);

    getYAxisKeys = () =>
        this.state.filters && this.state.filters.gender_id
            ? this.state.filters.gender_id
                .toString()
                .split(',')
                .map(genderId => `gender_${genderId}_value`)
            : [];

    generateLegendData = () => {
        const { gender_id } = this.state.filters;
        if (!gender_id) {
            return [];
        }

        return gender_id
            .toString()
            .split(',')
            .map(id => ({
                name: _(`gender_${id}`),
                icon: 'circle',
            }));
    };

    renderTooltip = (params, { filters, isMobileView }) => {
        const {
            measure_id,
            cause_id,
        } = this.state.filters;

        const tooltipFontSize = isMobileView ? 10 : 14;

        const getMarker = color =>
            `<span style="display:inline-block;margin-right:8px;border-radius:10px;width:10px;height:10px;background-color:${color};"></span>`;

        return `<div style="text-align:left;font-size:${tooltipFontSize}px">` +
            params.map(obj =>
                getMarker(obj.color.colorStops[0].color) +
                _(`trends_tooltip_measure_${measure_id}`,
                    {
                        gender: obj.seriesName,
                        value: `<b>${numeral(obj.value)
                            .format('0,0.0')}</b>`,
                        cause: _(`cause_${cause_id}`),
                        year: obj.axisValue,
                        separator: '<br/>&nbsp;&nbsp;&nbsp;&nbsp;',
                    }))
                .join('<br/>') + '</div>';
    };

    getLineParams = () =>
        this.state.filters && this.state.filters.gender_id
            ? this.state.filters.gender_id
                .toString()
                .split(',')
                .map((genderId, i) =>
                    ({
                        name: _(`gender_${genderId}`),
                        color: echartsTheme.line.colors[i],
                    }))
            : [];

    getSaveFilename = ({ filters, yearsRange }) => 'Trends, '
        + _(`cause_${filters.cause_id}`) + ' '
        + _(`measure_${filters.measure_id}`) + ' '
        + _(`metric_${filters.metric_id}`) + ' in '
        + _(`location_${filters.location_id}`) + ', '
        + _(`age_group_${filters.age_group_id}`) + ', '
        + _(`gender_${filters.gender_id}`) + ', '
        + yearsRange.range;

    loadData = filters => new Promise((resolve, reject) =>
        api.data.getCauseOutcome(filters)
            .then(response => {
                let {
                    columns,
                    records,
                } = response;

                if (records.length === 0) {
                    resolve({
                        columns,
                        records,
                    });
                }

                const genderIndex = columns.indexOf('gender_id');
                const valueIndex = columns.indexOf('value');
                const yearIndex = columns.indexOf('year');

                // merge genders data
                const genderIds = uniq(records.map(record => record[genderIndex]));
                const dataByGender = groupBy(records, record => record[genderIndex]);

                const reference = dataByGender[genderIds[0]];

                columns.push(...genderIds.map(genderId => `gender_${genderId}_value`));
                records = sortBy(reference, yearIndex)
                    .map(record => [
                        ...record,
                        ...genderIds.map(genderId =>
                            find(dataByGender[genderId],
                                genderRecord => genderRecord[yearIndex] === record[yearIndex])
                            [valueIndex]),
                    ]);

                resolve({
                    columns,
                    records,
                });
            })
            .catch(reject),
    );

    onFiltersChange = (updatedFilters, filters) => {
        this.setState({
            filters,
            availableMeasures: [],
        });

        const criteria = {
            filters: 'measure_id',
            cause_id: filters.cause_id,
        };

        api.data.getCauseOutcomeFilters(criteria)
            .then(response =>
                this.setState({ availableMeasures: response.measure_id }))
            .catch(console.log);
    };

    preprocessMeasures = measures => {
        const { availableMeasures } = this.state;

        return measures.map(measure => ({
            ...measure,
            isDisabled: availableMeasures.indexOf(measure.value) === -1,
        }));
    };

    render() {
        const {
            filters,
            extendedCauses,
            history,
            location,
        } = this.props;

        const preparedFilters = prepareCauseFilters(filters);

        return (
            <React.Fragment>
                <PageHeader>{_(locale.title)}</PageHeader>
                <Col xs={12} md={9} mdPush={3}>
                    <ChartProvider
                        key={preparedFilters}
                        loadFilters={() => Promise.resolve(preparedFilters)}
                        loadData={this.loadData}
                        defaultFilters={DEFAULT_FILTERS}
                        initialFilters={this.getInitialFilters()}
                        onFiltersChange={this.onFiltersChange}
                        history={history}
                        location={location}>

                        <Form inline>
                            <ButtonGroup>
                                <ChartDropdown
                                    label={_(locale.ageGroupsTitle)}
                                    localePrefix="age_group_"
                                    filterKey="age_group_id"
                                />
                                <ChartDropdown
                                    label={_(locale.gendersTitle)}
                                    localePrefix="gender_"
                                    filterKey="gender_id"
                                />
                                <ChartDropdown
                                    label={_(locale.measuresTitle)}
                                    localePrefix="measure_"
                                    filterKey="measure_id"
                                    preprocessOptions={this.preprocessMeasures}
                                />
                                <ChartDropdown
                                    label={_(locale.metricsTitle)}
                                    localePrefix="metric_"
                                    filterKey="metric_id"
                                />
                                <CausesDropdown
                                    key={extendedCauses}
                                    loadHierarchy={() => Promise.resolve(extendedCauses)}
                                    label={_(locale.causesTitle)}
                                    localePrefix="cause_"
                                    filterKey="cause_id"
                                    isSearchable
                                    rootLevel={0}
                                />
                                <LocationsDropdown
                                    label={_(locale.locationsTitle)}
                                    localePrefix="location_"
                                    filterKey="location_id"
                                    preprocessOptions={sortLocationOptions}
                                    isSearchable
                                />
                            </ButtonGroup>
                            <Clearfix />
                        </Form>

                        <LineChart
                            theme={echartsTheme}
                            withSlider
                            saveAsImage={{
                                visible: true,
                                filename: this.getSaveFilename,
                                icon: pngIcon,
                            }}
                            saveAsCSV={{
                                visible: true,
                                filename: this.getSaveFilename,
                                icon: csvIcon,
                                headers: ['Location', 'Year', 'Unit', 'Measure', 'Cause', 'Age', 'Gender', 'Value'],
                                keys: ['location_id', 'year', 'metric_id', 'measure_id', 'cause_id', 'age_group_id', 'gender_id', ...this.getYAxisKeys()],
                            }}
                            yAxisKeys={this.getYAxisKeys()}
                            lineParams={this.getLineParams()}
                            renderTooltip={this.renderTooltip}
                            renderTitle={({ filters, yearsRange }) =>
                                _(`cause_${filters.cause_id}`) + ' '
                                + _(`measure_${filters.measure_id}`) + ' '
                                + _(`metric_${filters.metric_id}`) + ' in '
                                + _(`location_${filters.location_id}`) + ' '
                                + yearsRange.range}
                            renderSubtitle={({ filters }) => _(`age_group_${filters.age_group_id}`)}
                            generateLegendData={this.generateLegendData}
                        />
                    </ChartProvider>
                </Col>
                <Col xs={12} md={3} mdPull={9}>
                    <p dangerouslySetInnerHTML={{ __html: _(locale.highlightText) }} />
                </Col>
            </React.Fragment>
        );
    }
}

TrendsScene.propTypes = propTypes;

export default compose(
    withRouter,
    connect(mapStateToProps, {
        loadExtendedCauses,
        loadFilters: loadTrendsFilters,
    }),
)(TrendsScene);
