export default {
    title: 'global_impact_scene_title',
    highlightText: 'global_impact_highlight_text',
    chartTitle: 'global_impact_chart_title',
    chartSubtitle: 'global_impact_chart_subtitle',
    saveAsImage: 'chart_save_as_image',
    saveAsCsv: 'chart_save_as_csv',
    ageGroupsTitle: 'filter_age_group_title',
    measuresTitle: 'filter_measure_title',
    metricsTitle: 'filter_metric_title',
};
