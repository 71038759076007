import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import numeral from 'numeral';
import queryString, { stringify } from 'query-string';
import remove from 'lodash/remove';

import _ from '@ihme/common/locale';
import styled from '@ihme/common/theme/styled';
import {
    Col,
    PageHeader,
    Clearfix,
    ChartDropdown,
    ChartProvider,
    ChartZoomProvider,
    Form,
    MapChart,
    MapChartZoomControls,
} from '@ihme/common/web/components';

import api from '../../api';
import config from '../../config';

import locale from './locale';
import { getGlobalImpactFilters } from '../../reducers';
import { loadGlobalImpactFilters } from '../../actions/data-actions';
import { TRENDS_PATH } from '../../router/paths';

import { csvIcon, pngIcon } from '../../theme/icons';
import echartsTheme from '../../theme/echartsTheme';

const ButtonGroup = styled('div')(({ theme }) => ({
    '> *': {
        width: 124,
        marginRight: 12,
        [theme.breakpoint.xs]: {
            width: 252,
        },
    },
}));

const StyledMapChartZoomControls = styled(MapChartZoomControls)(() => ({
    marginTop: 10,
    marginBottom: 10,
    float: 'right',
    'button + button': {
        marginLeft: 10,
    },
}));

const DEFAULT_FILTERS = {
    metric_id: config.globalImpactDefaultMetricId,
    age_group_id: config.globalImpactDefaultAgeGroupId,
    measure_id: config.globalImpactDefaultMeasureId,
};

const propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,

    filters: PropTypes.object,
    loadFilters: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    filters: getGlobalImpactFilters(state),
});

class GlobalImpactScene extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            globalValues: [],
        };
    }

    componentWillMount() {
        if (!this.props.filters) {
            this.props.loadFilters();
        }
    }

    loadData = params => new Promise((resolve, reject) =>
        api.data.getCauseOutcome({
            ...params,
            location_id: config.noSdiLocationIds, // exclude sdis values
            gender_id: config.bothGendersId,
            cause_id: config.hepatitisCauseId,
        })
            .then(response => {
                const { columns, records } = response;

                const locationIndex = columns.indexOf('location_id');
                const valueIndex = columns.indexOf('value');
                const yearIndex = columns.indexOf('year');

                const globalValues = records.filter(record =>
                    record[locationIndex] === config.globalLocationId)
                    .map(record => ({
                        year: record[yearIndex],
                        value: record[valueIndex],
                    }));

                this.setState({ globalValues });

                remove(records, record => record[locationIndex] === config.globalLocationId);

                resolve({
                    columns,
                    records,
                });
            })
            .catch(reject),
    );

    renderTooltip = params => {
        const {
            history,
            location,
        } = this.props;

        window.countrySelectParams = {
            id: params.data.id,
            callback: locationId => {
                history.push({
                    pathname: TRENDS_PATH,
                    search: `${location.search}&location_id=${locationId}`,
                });
            },
        };

        return `<span style="color:black">${params.data.name}:
            <b>${numeral(params.value).format('0,0')}</b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
                onclick="window.countrySelectParams.callback(window.countrySelectParams.id)"
                class="btn btn-default">
                &gt;
            </a>`;
    };

    onCountrySelect = location => {
        this.props.history.push({
            pathname: TRENDS_PATH,
            search: stringify({ location_id: location }),
        });
    };

    getInitialFilters = () => queryString.parse(this.props.location.search);

    getSaveFilename = ({ filters }) => (
        'GlobalImpact, ' +
        _(`measure_${filters.measure_id}`) + ' ' +
        _(`metric_${filters.metric_id}`) + ', ' +
        _(`age_group_${filters.age_group_id}`)
    );

    renderSubtitle = ({ filters, sliderValue }) => {
        const { globalValues } = this.state;
        const globalValue = globalValues && globalValues.length
            ? globalValues
                .find(record =>
                    record.year === sliderValue)
                .value
            : 0;

        const formattedValue = numeral(globalValue)
            .format('0,0.0a')
            .toUpperCase();

        return _(locale.chartSubtitle, { value: formattedValue });
    };

    render() {
        const {
            filters,
            history,
            location,
        } = this.props;

        return (
            <React.Fragment>
                <PageHeader>{_(locale.title)}</PageHeader>
                <Col xs={12} md={9} mdPush={3}>
                    <ChartProvider
                        key={filters}
                        loadFilters={() => Promise.resolve(filters)}
                        loadData={this.loadData}
                        defaultFilters={DEFAULT_FILTERS}
                        initialFilters={this.getInitialFilters()}
                        history={history}
                        location={location}>
                        <Form inline>
                            <ButtonGroup>
                                <ChartDropdown
                                    label={_(locale.metricsTitle)}
                                    localePrefix="metric_"
                                    filterKey="metric_id"
                                />
                                <ChartDropdown
                                    label={_(locale.measuresTitle)}
                                    localePrefix="measure_"
                                    filterKey="measure_id"
                                />
                                <ChartDropdown
                                    label={_(locale.ageGroupsTitle)}
                                    localePrefix="age_group_"
                                    filterKey="age_group_id"
                                />
                            </ButtonGroup>
                        </Form>

                        <ChartZoomProvider>
                            <div>
                                <StyledMapChartZoomControls/>
                                <Clearfix/>

                                <MapChart
                                    theme={echartsTheme}
                                    enableSliderWithFilterKey="year"
                                    initialSliderValue={config.latestYear}
                                    renderTooltip={this.renderTooltip}
                                    renderTitle={({ filters, sliderValue }) =>
                                        _(locale.chartTitle, { year: sliderValue })}
                                    renderSubtitle={this.renderSubtitle}
                                    saveAsImage={{
                                        visible: true,
                                        filename: this.getSaveFilename,
                                        icon: pngIcon,
                                    }}
                                    saveAsCSV={{
                                        visible: true,
                                        icon: csvIcon,
                                        filename: this.getSaveFilename,
                                        headers: ['Location', 'Unit', 'Measure', 'Year', 'Value'],
                                        keys: ['location_id', 'metric_id', 'measure_id', 'year', 'value'],
                                    }}
                                />
                            </div>
                        </ChartZoomProvider>
                    </ChartProvider>
                </Col>
                <Col xs={12} md={3} mdPull={9}>
                    <p dangerouslySetInnerHTML={{ __html: _(locale.highlightText) }}/>
                </Col>
            </React.Fragment>
        );
    }
}

GlobalImpactScene.propTypes = propTypes;

export default compose(
    withRouter,
    connect(mapStateToProps, { loadFilters: loadGlobalImpactFilters }),
)(GlobalImpactScene);
