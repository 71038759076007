import PropTypes from 'prop-types';
import React from 'react';
import debounce from 'lodash/debounce';

import Echarts from 'react-echarts-v3';
import echartsCore from 'echarts';
import 'echarts/lib/chart/map';
import 'echarts/lib/component/visualMap';
import worldJson from 'echarts/map/json/world.json';

import echartsTheme from '../../../../theme/echartsTheme';

const propTypes = {
    data: PropTypes.array,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    title: PropTypes.string,
    subtitle: PropTypes.string,

    isLoading: PropTypes.bool,
};

export default class View extends React.PureComponent {
    DEFAULT_ZOOM = 0.8;

    constructor(props) {
        super(props);

        this.state = {
            windowWidth: window.innerWidth,
            zoom: this.DEFAULT_ZOOM,
        };

        this.onResize = debounce(this.onResize.bind(this), 250);
    }

    componentDidMount() {
        echartsCore.registerMap('world', worldJson);
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        this.setState({ windowWidth: window.innerWidth });
    }

    render() {
        return (
            <div style={{ pointerEvents: 'none' }}>
                <Echarts
                    resizable
                    loading={this.props.isLoading}
                    optsLoading={echartsTheme.loading}
                    theme={echartsTheme}
                    style={{
                        height: Math.min(Math.max(this.state.windowWidth / 2.4, 240), 490),
                        ...styles.container,
                    }}
                    option={{
                        title: {
                            text: this.props.title,
                            subtext: this.props.subtitle,
                        },

                        visualMap: {
                            show: false,
                            min: this.props.minValue,
                            max: this.props.maxValue,
                            ...styles.visualMap,
                        },
                        series: [
                            {
                                zoom: this.state.zoom,
                                data: this.props.data,
                                ...styles.chart,
                            },
                        ],
                    }}
                />
            </div>
        );
    }
}

const styles = {
    container: {
        width: '100%',
        margin: '0 0 20pt 0',
    },
    chart: {
        type: 'map',
        map: 'world',
        selectedMode: 'single',
        center: [0, -20],
        roam: 'move',
        top: 100,
        height: '90%',
        itemStyle: {
            normal: {
                borderWidth: 0.5,
                borderColor: 'white',
            },
        },
    },
    visualMap: {
        realtime: true,
        calculable: true,
        top: 20,
        itemWidth: 6,
        itemHeight: 350,
        inRange: {
            color: [
                '#199949',
                '#20C05C',
                '#4CCC7C',
                '#82DBA3',
                '#FBDD39',
                '#F7B600',
                '#F18602',
                '#E86D0C',
            ],
        },
        outOfRange: {
            color: '#ECEFF1',
        },
    },
};

View.propTypes = propTypes;
