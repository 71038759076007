import { merge } from 'lodash/fp';

import defaultTheme from '@ihme/common/theme/echarts-theme';

const textStyle = {
    fontSize: 14,
    fontFamily: 'Helvetica, sans-serif',
    color: '#333333',
};

const colorPalette = [
    '#1FBA59',
    '#FBDD39',
    '#D73146',
    '#5C6BC0',
    '#50C3F7',
    '#FB9C39',
    '#8560C5',
    '#CBED8C',
    '#795548',
];

export default merge(defaultTheme, {
    color: colorPalette,
    loading: {
        text: 'Loading...',
        color: '#20C05C',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.8)',
        zlevel: 0,
    },
    textStyle,
    title: {
        top: 0,
        left: 'center',
        backgroundColor: 'rgba(255, 255, 255, .8)',
        textStyle: {
            fontFamily: 'Aleo',
            fontSize: 20,
            fontWeight: 'bold',
        },
        subtextStyle: {
            fontSize: 14,
            color: '#333333',
        },
    },
    toolbox: {
        top: 24,
        right: 26,
        itemSize: 26,
        itemGap: 20,
        iconStyle: {
            color: 'rgba(0, 0, 0, .6)',
            borderWidth: 0,
            emphasis: {
                color: '#1FBA59',
            },
        },
    },
    tooltip: {
        backgroundColor: 'rgba(255,255,255)',
        extraCssText: 'box-shadow: 0 10px 10px 0 rgba(0,0,0,0.05), 0 0 10px 0 rgba(0,0,0,0.10), 0 20px 20px 0 rgba(0,0,0,0.08); border-radius: 4px;',
        padding: [16, 20],
        textStyle,
    },
    xAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#ECEFF1',
                width: 2,
            },
        },
        axisLabel: {
            ...textStyle,
            showMaxLabel: true,
        },
        axisTick: {
            length: 2,
            lineStyle: {
                width: 2,
            },
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: '#ECEFF1',
                opacity: 0.8,
            },
        },
        nameLocation: 'middle',
        nameTextStyle: textStyle,
        nameGap: 30,
    },
    yAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#ECEFF1',
                width: 2,
            },
        },
        axisTick: {
            length: 2,
            lineStyle: {
                width: 2,
            },
        },
        axisLabel: textStyle,
        splitLine: {
            show: true,
            lineStyle: {
                color: '#ECEFF1',
                opacity: 0.8,
            },
        },
        nameLocation: 'middle',
        nameTextStyle: {
            ...textStyle,
            fontWeight: 600,
        },
        nameGap: 54,
    },
    dataZoom: {
        type: 'slider',
        textStyle: {
            fontSize: 12,
        },
        backgroundColor: '#F5F7F8',
        fillerColor: 'rgba(194,201,204, 0.25)',
        borderColor: 'transparent',
        dataBackground: {
            lineStyle: {
                color: '#ACB8BE',
            },
            areaStyle: {
                color: '#DDE3E6',
            },
        },
        handleStyle: {
            color: '#C2C9CC',
        },
    },
});
