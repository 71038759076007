import PropTypes from 'prop-types';

export const localeTypes = PropTypes.shape({
    title: PropTypes.string.isRequired,
    deathsAmount: PropTypes.string.isRequired,
});

export default {
    title: 'factsheets_scene_yll_highlight_title',
    lowestRates: 'factsheets_scene_yll_lowest_rates',
    highestRates: 'factsheets_scene_yll_highest_rates',
    locationStat: 'factsheets_scene_yll_location_stat',
};
