import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import _ from '@ihme/common/locale';
import locale from './locale';

const propTypes = {
    locationId: PropTypes.number.isRequired,
    locationValue: PropTypes.number.isRequired,
    lowestLocations: PropTypes.array.isRequired,
    highestLocations: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

class View extends React.PureComponent {
    render() {
        // @todo: add loading indicator
        return (
            <article style={styles.container}>
                <section style={styles.sectionContainer}>
                    <h2 style={[styles.sectionHeader, { color: '#E86D0C' }]}>
                        {_(locale.highestRates)}
                    </h2>
                    {this.props.highestLocations.map((locationId, index) =>
                        <p style={{ margin: 0 }} key={locationId + ' ' + index}>
                            <span>&nbsp;&nbsp;{index + 1}.&nbsp;&nbsp;&nbsp;</span>{_(`location_${locationId}`)}
                        </p>,
                    )}
                </section>

                <section style={styles.sectionContainer}>
                    <h2 style={styles.sectionHeader}>{_(locale.lowestRates)}</h2>
                    {this.props.lowestLocations.map((locationId, index) =>
                        <p style={{ margin: 0 }} key={locationId + ' ' + index}>
                            <span>&nbsp;&nbsp;{index + 1}.&nbsp;&nbsp;&nbsp;</span>{_(`location_${locationId}`)}
                        </p>,
                    )}
                </section>

                <p style={[styles.sectionContainer, styles.stats]}>
                    {_(locale.locationStat, {
                        location: _(`location_${this.props.locationId}`),
                        years: Math.round(this.props.locationValue),
                    })}
                </p>
            </article>
        );
    }
}

const styles = {
    container: {
        backgroundColor: '#F5F7F8',
        padding: 15,
    },
    sectionContainer: {
        padding: 15,
        margin: 0,
    },
    sectionHeader: {
        marginTop: 0,
        marginBottom: 10,
        color: 'green',
    },
    stats: {
        fontWeight: 600,
    },
};

View.propTypes = propTypes;

export default Radium(View);
