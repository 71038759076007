import PropTypes from 'prop-types';

export const localeTypes = PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    selectLocation: PropTypes.string.isRequired,
});

export default {
    title: 'factsheets_scene_title',
    subTitle: 'factsheets_scene_sub_title',
    selectLocation: 'factsheets_scene_location_select',
};
