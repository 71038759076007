import PropTypes from 'prop-types';

export const localeTypes = PropTypes.shape({
    title: PropTypes.string.isRequired,
    more: PropTypes.string.isRequired,
    less: PropTypes.string.isRequired,

    whatIsHepTitle: PropTypes.string.isRequired,
    whatIsHepDescription: PropTypes.string.isRequired,
    whatIsHepText: PropTypes.string.isRequired,

    globalGoalsTitle: PropTypes.string.isRequired,
    globalGoalsDescription: PropTypes.string.isRequired,
    globalGoalsText: PropTypes.string.isRequired,

    burdenRiskTrendsTitle: PropTypes.string.isRequired,
    burdenRiskTrendsDescription: PropTypes.string.isRequired,
    burdenRiskTrendsText: PropTypes.string.isRequired,
});

export default {
    title: 'highlights_title',
    more: 'highlights_more',
    less: 'highlights_less',

    whatIsHepTitle: 'highlights_what_is_hep_title',
    whatIsHepDescription: 'highlights_what_is_hep_description',
    whatIsHepText: 'highlights_what_is_hep_text',

    globalGoalsTitle: 'highlights_global_goals_title',
    globalGoalsDescription: 'highlights_global_goals_description',
    globalGoalsText: 'highlights_global_goals_text',

    burdenRiskTrendsTitle: 'highlights_burden_risk_trends_title',
    burdenRiskTrendsDescription: 'highlights_burden_risk_trends_description',
    burdenRiskTrendsText: 'highlights_burden_risk_trends_text',
};
