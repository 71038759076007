import React from 'react';
import Radium from 'radium';
import Grid from 'react-bootstrap/lib/Grid';
import Navigation from '../Navigation';
import Footer from '../Footer';

const Layout = props =>
    <div>
        <div style={styles.navbarContainer}>
            <Navigation style={styles.navbar} />
        </div>

        <Grid>
            {props.children}
        </Grid>

        <Footer style={styles.footer} />
    </div>;

const styles = {
    navbarContainer: {
        marginTop: 40,
        marginBottom: 60,
        '@media (max-width: 768px)': {
            marginTop: 0,
            marginBottom: 10,
        },
    },
    footer: {
        marginTop: 80,
        marginBottom: 20,
        '@media (max-width: 768px)': {
            marginTop: 20,
        },
    },
    navbar: {
        boxShadow: 'none',
    },
};

export default Radium(Layout);
