import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import html2canvas from 'html2canvas';
import FileSaver from 'file-saver';
import { stringify } from 'query-string';

import config from '../../config';
import _ from '@ihme/common/locale';

import View from './View';

const propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    locations: PropTypes.array.isRequired,
    locationId: PropTypes.number.isRequired,
};

export default class Container extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            locationId: props.locationId,
        };

        this.onLocation = this.onLocation.bind(this);
        this.onScreenshot = this.onScreenshot.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.locationId !== this.state.locationId) {
            this.setState({ locationId: nextProps.locationId });
        }
    }

    onScreenshot(view) {
        const fileName = `Factsheets About Hepatitis In ${_('location_' + this.props.locationId)}.png`;

        html2canvas(view, { useCORS: true, logging: false })
            .then(canvas => {
                // https://github.com/eligrey/FileSaver.js#saving-a-canvas
                canvas.toBlob(blob =>
                    FileSaver.saveAs(blob, fileName)
                )
            })
            .catch(console.err);
    }

    onLocation({ id }) {
        this.setState({ locationId: id });
        this.syncQueryString(id);
    }

    syncQueryString(locationId) {
        const params = {};

        if (locationId !== config.defaultLocationId) {
            params.location_id = locationId;
        }
        const {
            history,
            location,
        } = this.props;

        history.push({
            pathname: location.pathname,
            search: stringify(params),
        });
    }

    render() {
        return (
            <View
                locations={this.props.locations}
                selectedLocationId={this.state.locationId}
                onLocation={this.onLocation}
                onScreenshot={this.onScreenshot}
            />
        );
    }
}

Container.propTypes = propTypes;
