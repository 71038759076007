import React from 'react';
import { hot } from 'react-hot-loader';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { createStore } from '@ihme/common/store';
import BaseRoot from '@ihme/common/components/Root';

import Router from '../../router';
import reducers from '../../reducers';
import config from '../../config';

class Root extends React.PureComponent {
    createStore = (state) => {
        state = omit(state, ['version']);

        return new Promise((resolve, reject) => resolve(
            createStore(state, reducers),
        ));
    }

    render() {
        return (
            <BaseRoot
                router={<Router />}
                createStore={this.createStore}
                storageVersion={config.storageVersion}
                stateToLocalStorage={state => pick(state, ['version', 'data'])}
            />
        );
    }
}

export default hot(module)(Root);
