import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Radium from 'radium';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Collapse from 'react-bootstrap/lib/Collapse';

import _ from '@ihme/common/locale';
import locale from './locale';

const propTypes = {
    description: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    iconSrc: PropTypes.string.isRequired,
};

const defaultProps = {
};

class HighlightItem extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        this.onToggle = this.onToggle.bind(this);
    }

    onToggle() {
        this.setState({ open: !this.state.open });
    }

    render() {
        return (
            <Row>
                <Col sm={3}>
                    <img style={styles.image} src={this.props.iconSrc} onClick={this.onToggle} />
                </Col>
                <Col sm={9}>
                    <article style={styles.article}>
                        <h5>
                            {this.props.title}
                        </h5>
                        <div style={styles.container} onClick={this.onToggle}>
                            <p
                                style={styles.paragraph}
                                dangerouslySetInnerHTML={{ __html: this.props.description }}
                            />
                            {!this.state.open && (
                                <a>
                                    {_(locale.more)}
                                </a>
                            )}

                            <Collapse in={this.state.open} onClick={this.onToggle}>
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: this.props.text }} />
                                    {this.state.open &&
                                        <a style={styles.toggleText}>{_(locale.less)}</a>
                                    }
                                </div>
                            </Collapse>
                        </div>
                    </article>
                </Col>
            </Row>
        );
    }
}

const styles = {
    container: {
        backgroundColor: 'white',
        marginBottom: 20,
        minHeight: 134,
        cursor: 'pointer',
    },
    icon: {
        float: 'left',
        margin: '0pt 10pt 0pt 0pt',
        width: 120,
    },
    title: {
        marginTop: 2,
        marginBottom: 6,
    },
    paragraph: {
        marginBottom: 10,
    },
    image: {
        width: '100%',
        borderRadius: 4,
        backgroundColor: '#f5f7f8',
        cursor: 'pointer',
        ':hover': {
            opacity: 0.8,
        },
    },
    article: {
        marginBottom: 30,
    },
};

HighlightItem.propTypes = propTypes;
HighlightItem.defaultProps = defaultProps;

export default Radium(HighlightItem);
