import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Layout from '../components/Layout';
import GATracker from '../components/GATracker';
import HomeScene from '../components/HomeScene';
import GlobalImpactScene from '../components/GlobalImpactScene';
import TrendsScene from '../components/TrendsScene';
import BreakdownScene from '../components/BreakdownScene';
import FactsheetsScene from '../components/FactsheetsScene';
import AboutScene from '../components/AboutScene';

import {
    ABOUT_PATH,
    HOME_PATH,
    GLOBAL_IMPACT_PATH,
    TRENDS_PATH,
    BREAKDOWN_PATH,
    FACTSHEETS_PATH,
} from './paths';

const LayoutRoute = ({ component: Component, ...rest }) =>
    <Route
        {...rest}
        render={props => (
            <Layout>
                <Component {...props} />
            </Layout>
        )}
    />;

export default () => (
    <Router>
        <div>
            <GATracker />
            <Route path={HOME_PATH} exact component={HomeScene} />
            <LayoutRoute path={GLOBAL_IMPACT_PATH} exact component={GlobalImpactScene} />
            <LayoutRoute path={TRENDS_PATH} exact component={TrendsScene} />
            <LayoutRoute path={BREAKDOWN_PATH} exact component={BreakdownScene} />
            <LayoutRoute path={FACTSHEETS_PATH} exact component={FactsheetsScene} />
            <LayoutRoute path={ABOUT_PATH} exact component={AboutScene} />
        </div>
    </Router>
);
