import * as types from './types';
import api from '../api';
import config from '../config';

export const loadExtendedCauses = () => dispatch =>
    api.getExtendedCauses('', 1, 1000)
        .then(response => {
            const causes = response.results;
            dispatch({
                type: types.EXTENDED_CAUSES_LOADED,
                payload: causes,
            });

            return causes;
        });

export const loadTrendsFilters = () => dispatch =>
    api.data.getCauseOutcomeFilters({ cause_id: '!' + config.nonHepatitisCauseIds })
        .then(filters => {
            dispatch({
                type: types.TRENDS_FILTERS_LOADED,
                payload: filters,
            });

            return filters;
        });

export const loadGlobalImpactFilters = () => dispatch =>
    api.data.getCauseOutcomeFilters({
        cause_id: config.hepatitisCauseId,
        location_id: config.noSdiLocationIds,
        gender_id: config.bothGendersId,
    })
        .then(filters => {
            dispatch({
                type: types.GLOBAL_IMPACT_FILTERS_LOADED,
                payload: filters,
            });

            return filters;
        });

export const loadBreakdownFilters = () => dispatch =>
    api.data.getCauseOutcomeFilters({
        gender_id: config.bothGendersId,
        measure_id: config.prevalenceMeasureId,
        cause_id: config.breakdownCauses.join(','),
    })
        .then(filters => {
            dispatch({
                type: types.BREAKDOWN_FILTERS_LOADED,
                payload: filters,
            });

            return filters;
        });

export const loadFactsFilters = () => dispatch =>
    api.data.getCauseOutcomeFilters({ filters: 'location_id' })
        .then(filters => {
            dispatch({
                type: types.FACTS_FILTERS_LOADED,
                payload: filters,
            });

            return filters;
        });

export const loadGlobalYllData = () => dispatch =>
    api.data.getCauseOutcome({
        age_group_id: config.allAgesGroupId,
        gender_id: config.bothGendersId,
        measure_id: config.yllMeasureId,
        metric_id: config.rateMetricId,
        cause_id: config.hepatitisCauseId,
        year: config.latestYear,
    })
        .then(filters => {
            dispatch({
                type: types.GLOBAL_YLL_DATA_LOADED,
                payload: filters,
            });

            return filters;
        });

export const loadCountries = () => dispatch =>
    api.getLocations({ type_id: '2,8' })
        .then(filters => {
            dispatch({
                type: types.COUNTRIES_LOADED,
                payload: filters,
            });

            return filters;
        });
