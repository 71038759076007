export default {
    footer: 'home_scene_footer',
    introText: 'home_scene_intro_text',
    globalImpactTitle: 'feature_bar_global_impact_title',
    globalImpactText: 'feature_bar_global_impact_text',
    globalImpactButton: 'feature_bar_global_impact_button',
    trendsTitle: 'feature_bar_trends_title',
    trendsText: 'feature_bar_trends_text',
    prevalenceTitle: 'feature_bar_breakdown_title',
    prevalenceText: 'feature_bar_breakdown_text',
    factsTitle: 'feature_bar_facts_title',
    factsText: 'feature_bar_facts_text',
};
