import React from 'react';
import { Link } from 'react-router-dom';
import Radium from 'radium';

import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import _ from '@ihme/common/locale';
import locale from './locale';

import {
    GLOBAL_IMPACT_PATH,
    TRENDS_PATH,
    BREAKDOWN_PATH,
    FACTSHEETS_PATH,
} from '../../router/paths';
import Navigation from '../../components/Navigation';

import NewsList from './NewsList';
import HighlightsList from './HighlightsList';

class HomeScene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            onMouseOverFeature: null,
        };
    }

    onFeatureMouseOver = onMouseOverFeature => () => {
        this.setState({ onMouseOverFeature });
    };

    render() {
        return (
            <div>
                <section>
                    <div className="home_hero">
                        <div style={{
                            marginTop: 40,
                        }}>
                            <Navigation />
                        </div>

                        <Grid>
                            <div style={{
                                textAlign: 'left',
                                marginTop: 100,
                                '@media (max-width: 707px)': {
                                    marginLeft: 15,
                                },
                            }}>
                                <Row>
                                    <h1>
                                        {_(locale.globalImpactTitle)}
                                    </h1>
                                    <p style={{ marginBottom: 40 }}>
                                        {_(locale.globalImpactText)}
                                    </p>
                                    <Link to={GLOBAL_IMPACT_PATH}>
                                        <div className="home_button">
                                            {_(locale.globalImpactButton)}
                                        </div>
                                    </Link>
                                </Row>
                            </div>
                        </Grid>
                    </div>
                </section>

                <Grid className="homepage_main">
                    <div style={styles.mainSection}>
                        <Row className="homepage_cards">
                            {[
                                {
                                    name: 'trends',
                                    path: TRENDS_PATH,
                                    img: '/img/trends_box.png',
                                    hoverImg: '/img/trends_box_hover.png',
                                    title: _(locale.trendsTitle),
                                    text: _(locale.trendsText),
                                },
                                {
                                    name: 'breakdown',
                                    path: BREAKDOWN_PATH,
                                    img: '/img/breakdown_box.png',
                                    hoverImg: '/img/breakdown_box_hover.png',
                                    title: _(locale.prevalenceTitle),
                                    text: _(locale.prevalenceText),
                                },
                                {
                                    name: 'factsheets',
                                    path: FACTSHEETS_PATH,
                                    img: '/img/factsheets_box.png',
                                    hoverImg: '/img/factsheets_box_hover.png',
                                    title: _(locale.factsTitle),
                                    text: _(locale.factsText),
                                },
                            ].map(feature => (
                                <Col xs={12} sm={4} key={feature.name}>
                                    <Link
                                        to={feature.path}
                                        style={styles.featureItemLink}
                                        onMouseOver={this.onFeatureMouseOver(feature.name)}
                                        onMouseOut={this.onFeatureMouseOver(null)}>
                                        <div className="home_card">
                                            <img
                                                style={styles.image}
                                                src={this.state.onMouseOverFeature === feature.name
                                                    ? feature.hoverImg
                                                    : feature.img
                                                }
                                            />
                                            <article>
                                                <h5
                                                    style={this.state.onMouseOverFeature === feature.name
                                                        ? styles.featureTitleHover
                                                        : styles.featureTitle
                                                    }>
                                                    {feature.title}
                                                </h5>
                                                <p>{feature.text}</p>
                                            </article>
                                        </div>
                                    </Link>
                                </Col>
                            ))}
                        </Row>

                        <Row className="homepage_highlights">
                            <HighlightsList />
                        </Row>
                    </div>
                </Grid>

                <section className="home_footer home_gradient">
                    <Grid>
                        <NewsList />
                        <footer dangerouslySetInnerHTML={{ __html: _(locale.footer) }} />
                    </Grid>
                </section>
            </div>
        );
    }
}

const styles = {
    mainSection: {
        marginTop: 40,
        marginBottom: 40,
        '@media (max-width: 767px)': {
            marginTop: 20,
            marginBottom: 20,
        },
    },
    image: {
        width: '100%',
        backgroundColor: '#f5f7f8',
    },
    featureItemLink: {
        textDecoration: 'none',
        borderRadius: 4,
    },
    featureTitle: {
        color: '#333333',
    },
    featureTitleHover: {
        color: '#199949',
    },
};

export default Radium(HomeScene);
